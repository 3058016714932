import { useState, useEffect } from "react";
import { Transition } from "@headlessui/react";
import "./LaunchpadCard.css";

const LaunchpadCard = ({ org, onSelect, index }) => {
  const [isShowing, setIsShowing] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsShowing(true);
    }, index * 50);
  }, []);

  return (
    <Transition
      show={isShowing}
      enter={`transition-opacity duration-500`}
      enterFrom="opacity-0"
      enterTo="opacity-100"
    >
      <div
        className="launchpad-card-wrapper bg-gradient-to-r from-antbuddy/70 to-antbuddy-nd/70 hover:from-antbuddy hover:to-antbuddy-nd"
        onClick={() => onSelect(org)}
      >
        <p className="org-name">{org.name}</p>
        <p className="org-domain">
          {org.domain}.{process.env.REACT_APP_HOST}
        </p>
      </div>
    </Transition>
  );
};

export default LaunchpadCard;
