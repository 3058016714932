import { IconChevronLeft, IconCreditCard } from "@tabler/icons-react";
import classes from "./Payment.module.css";
import Button from "../../components/Upgrade/UI/Button";
import { Checkbox, Form, Input } from "antd";
import { useEffect, useState, useContext } from "react";
import NotiPayment from "./NotiPayment";
import Cookies from "js-cookie";
import Logo from "../../imgs/LogoAntBuddy.png";
import { useTranslation } from "react-i18next";

import {
  convertDateTime,
  convertMoney,
  convertPackageName,
} from "../../utils/common";
import InputNum from "../../components/Upgrade/UI/InputNum";
import { useAccountState } from "../../context/AccountContext";
import { PackageData } from "../Upgrade/type";
import API from "../../apis";
import VietQRCode from "../../components/VietQR/VietQRCode";
import SelectBox from "../../components/Upgrade/UI/SelectBox";
import { SocketContext } from "../../context/SocketContext";

const Payment = () => {
  const socket = useContext(SocketContext);

  useEffect(() => {
    var token = Cookies.get("token");
    var domain = Cookies.get("domain");
    socket.emit("vietqr-authenticate", {
      token: token,
      domain: domain,
    });

    socket.on("response", (data) => {
      setIsModalOpen(true);
      if (data === "true") {
        setIsSuccess(true);
      } else {
        setIsSuccess(false);
      }
    });
  }, [socket]);
  //Socket
  const [isSuccess, setIsSuccess] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const { t } = useTranslation();

  const { user }: any = useAccountState();
  const [isShowQR, setIsShowQR] = useState(false);
  const [buyPackage, setBuyPackage] = useState<PackageData>();
  const [name, setName] = useState(user.name);
  const [org, setOrg] = useState("");

  type CustomerInfo = {
    fullName?: string;
    phoneNumber?: string;
    email?: string;
    bill?: string;
  };

  useEffect(() => {
    (async () => {
      try {
        let cred = Cookies.get("credential");
        const credential = JSON.parse(cred);

        const [userId, orgKey] = credential.identity.split("_");
        const org = user.listOrgs.find((o) => o.key === orgKey);
        setOrg(org);

        const buyPackageValue = await API.Upgrade.getProcessPackage(org);
        if (buyPackageValue.data.data) {
          setBuyPackage(buyPackageValue.data.data);
        } else {
        }
        if (isShowQR) {
        }
      } catch (err) {
        console.error(err, "[error get buy package]");
      }
    })();
  }, []);

  return (
    <div
      className={`${classes.container} ${
        !isShowQR ? classes["container-paymentInfo"] : ""
      }`}
    >
      <div className={classes.header}>
        <div
          className={classes.button}
          onClick={() => {
            if (isShowQR) {
              setIsShowQR(false);
            } else {
              window.history.back();
              socket.disconnect();
            }
          }}
        >
          <IconChevronLeft />
        </div>
        <span>{t("payment")}</span>
      </div>
      {!isShowQR && (
        <>
          <div className={classes.content}>
            <div className={classes.item}>
              <div className={classes.title}>{t("paymentInfo")}</div>
              <div className={classes.info}>
                <div className={classes["text-field"]}>
                  <Form.Item
                    label={t("fullName")}
                    name="fullName"
                    rules={[
                      { required: true, message: "Vui lòng nhập họ và tên" },
                    ]}
                  >
                    <Input
                      defaultValue={user.name}
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setName(event.target.value);
                      }}
                    />
                  </Form.Item>

                  <Form.Item label={t("phone")} name="phoneNumber">
                    <Input disabled={true} defaultValue={user?.phone} />
                  </Form.Item>

                  <Form.Item<CustomerInfo> label="Email" name="email">
                    <Input disabled={true} defaultValue={user?.email} />
                  </Form.Item>
                </div>

                {/* <Form.Item<CustomerInfo>
                    name="bill"
                    valuePropName="checked"
                    wrapperCol={{ offset: 8, span: 16 }}
                  >
                    <Checkbox>Nhận hóa đơn điện tử</Checkbox>
                  </Form.Item> */}
              </div>
            </div>
            <div className={classes.item}>
              <div className={classes.title}>{t("serviceList")}</div>
              <div className={classes.info}>
                <table>
                  <thead>
                    <tr>
                      <th>{t("package")}</th>
                      <th>{t("time")}</th>
                      <th>{t("user")}</th>
                      <th>{t("unitPricePackage")}</th>
                      <th>{t("startDay")}</th>
                      <th>{t("endDay")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {buyPackage?.packages.map((element, index) => (
                      <tr key={index}>
                        <td>
                          {convertPackageName(
                            element.name,
                            t("ominichannelChat"),
                            t("cloudCallCenter"),
                            t("omnichannelRetailSolution"),
                            t("omnichannelCustomerService"),
                            t("omnichannelCSSale")
                          )}
                        </td>
                        <td>
                          <SelectBox numOfMonth={element.numberOfMonth} />
                        </td>
                        <td>
                          <InputNum
                            disabled
                            step={element.name === "CLOUD" ? 5 : 1}
                            defaultValue={element.numberOfUser}
                          />
                        </td>
                        <td>
                          {convertMoney(element.amountPerUnit.toString())} Đ
                        </td>
                        <td>
                          {convertDateTime(
                            buyPackage ? buyPackage.paymentOpen : ""
                          )}
                        </td>
                        <td>
                          {convertDateTime(
                            buyPackage ? buyPackage.paymentExpired : ""
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className={classes.item}>
              <div className={classes.title}>{t("orderInfo")}</div>
              <div className={classes.info}>
                <div className={classes.row}>
                  <div>{t("subTotal")}</div>
                  <div className={classes.bold}>
                    {convertMoney(
                      buyPackage ? buyPackage.totalAmount.toString() : ""
                    )}{" "}
                    Đ
                  </div>
                </div>
                <div className={classes.row}>
                  <div>{t("discount")}</div>
                  <div className={classes.bold}>
                    -{" "}
                    {convertMoney(
                      buyPackage ? buyPackage.discount.toString() : ""
                    )}{" "}
                    Đ
                  </div>
                </div>
                <div className={classes.row}>
                  <div>{t("vat")}</div>
                  <div className={classes.bold}>
                    +{" "}
                    {convertMoney(buyPackage ? buyPackage.vat.toString() : "")}{" "}
                    Đ
                  </div>
                </div>
                <div className={classes.row}>
                  <div className={classes.bold}>{t("total")}</div>
                  <div className={classes.total}>
                    {convertMoney(
                      buyPackage ? buyPackage.amountPayable.toString() : ""
                    )}{" "}
                    Đ
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.footer}>
            <Button
              radius
              button={t("payment")}
              icon={<IconCreditCard />}
              onClick={async () => {
                const updateBuyPackage = {
                  customername: name,
                  status: "processing",
                };

                try {
                  const { data } = await API.Upgrade.editStateNewPackage(
                    org,
                    buyPackage?.orderid,
                    updateBuyPackage
                  );
                  if ((data.status = 200)) {
                    setIsShowQR(true);
                  }
                } catch (err) {
                  console.error(err, "error add package");
                }
              }}
            />
          </div>
        </>
      )}
      {isShowQR && (
        <>
          <div className={`${classes.content} ${classes["qr-ctn"]}`}>
            <div className={classes.item}>
              <div className={classes.title}>{t("qrNoti")}</div>
              <div className={classes["sub-content"]}>
                <div className={classes.qrcode}>
                  <img
                    className={classes["ablogo-img"]}
                    src={Logo}
                    alt="antbuddy.com"
                  ></img>
                  <VietQRCode
                    qrInfo={{
                      name: buyPackage ? buyPackage.customername : "",
                      amount: buyPackage ? buyPackage.amountPayable : 0,
                      orderId: buyPackage ? buyPackage.orderid : "",
                    }}
                  />
                </div>
                <div className={classes["order-info"]}>
                  <div className={classes.title}>{t("orderInfo")}</div>
                  <div className={classes.info}>
                    <div className={classes.row}>
                      <div>{t("subTotal")}</div>
                      <div className={classes.bold}>
                        {convertMoney(
                          buyPackage ? buyPackage.totalAmount.toString() : ""
                        )}{" "}
                        Đ
                      </div>
                    </div>
                    <div className={classes.row}>
                      <div>{t("discount")}</div>
                      <div className={classes.bold}>
                        -{" "}
                        {convertMoney(
                          buyPackage ? buyPackage.discount.toString() : ""
                        )}{" "}
                        Đ
                      </div>
                    </div>
                    <div className={classes.row}>
                      <div>{t("vat")}</div>
                      <div className={classes.bold}>
                        +{" "}
                        {convertMoney(
                          buyPackage ? buyPackage.vat.toString() : ""
                        )}{" "}
                        Đ
                      </div>
                    </div>
                    <div className={classes.row}>
                      <div className={classes.bold}>{t("total")}</div>
                      <div className={classes.total}>
                        {convertMoney(
                          buyPackage ? buyPackage.amountPayable.toString() : ""
                        )}{" "}
                        Đ
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <NotiPayment
        handleCancel={handleCancel}
        isModalOpen={isModalOpen}
        // isModalOpen={true}
        isSuccess={isSuccess}
        // isSuccess={true}
        amountPayable={convertMoney(
          buyPackage ? buyPackage.amountPayable.toString() : ""
        )}
        paymentExpired={convertDateTime(
          buyPackage ? buyPackage.paymentExpired : ""
        )}
        numberOfMonth={buyPackage ? buyPackage.packages[0].numberOfMonth : 0}
        packagesName={
          buyPackage
            ? buyPackage.packages
                .map((item) =>
                  convertPackageName(
                    item.name,
                    t("ominichannelChat"),
                    t("cloudCallCenter"),
                    t("omnichannelRetailSolution"),
                    t("omnichannelCustomerService"),
                    t("omnichannelCSSale")
                  )
                )
                .join(",")
            : ""
        }
      />
    </div>
  );
};

export default Payment;