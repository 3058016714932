import classes from "./NotiPayment.module.css";
import Button from "../../components/Upgrade/UI/Button";
import { Modal } from "antd";
import successPayment from "../../imgs/successPayment.png";
import failPayment from "../../imgs/failPayment.png";
import { useTranslation } from "react-i18next";

interface NotiPaymentProps {
  isSuccess: boolean;
  isModalOpen: boolean;
  handleCancel: () => void;
  amountPayable: string;
  paymentExpired: string;
  numberOfMonth: number;
  packagesName: string;
}

const NotiPayment: React.FC<NotiPaymentProps> = ({
  isModalOpen,
  isSuccess,
  handleCancel,
  amountPayable,
  paymentExpired,
  numberOfMonth,
  packagesName,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      {isSuccess ? (
        <Modal open={isModalOpen} onCancel={handleCancel} width={650}>
          <img className={classes.img} src={successPayment}></img>
          <p className={classes.title}>{t("successNoti")}</p>
          <div className={classes.content}>
            {t("successMess1")}
            <span className={classes.bold}> {amountPayable} Đ </span>
            {t("successMess2")}
            <span className={classes.bold}> {packagesName} </span>
            {t("successMess3")}. {t("successMess4")} {numberOfMonth}{" "}
            {t("month")}.
          </div>
          <div className={classes.content}>
            <div>{t("expired")}</div>
            <div className={classes.expired}>{paymentExpired}</div>
          </div>
          <div
            className={classes.button}
            onClick={() => {
              window.open("/upgrade", "_blank");
            }}
          >
            <Button radius button="OK" icon={<></>} />
          </div>
        </Modal>
      ) : (
        <Modal open={isModalOpen} onCancel={handleCancel} width={650}>
          <img className={classes.img} src={failPayment}></img>
          <p className={classes.title}>{t("failNoti")}</p>
          <div className={classes.content}>{t("failMess")}</div>
          <div className={classes.button} onClick={handleCancel}>
            <Button radius button={t("fail")} icon={<></>} />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default NotiPayment;