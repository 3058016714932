import { createContext } from "react";
import io, { Socket } from "socket.io-client";
console.log("socket context");

export const socket: Socket = io("https://antbuddy.com");

socket.on("connect", () => {
  console.log("connect websocket");
});

export const SocketContext = createContext<Socket>(socket);
