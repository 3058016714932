// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import UserAvatar from "react-user-avatar";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import classes from "./UserProfile.module.css";
import Cookies from "js-cookie";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="#"
    className="d-flex justify-content-center align-items-center header-text"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick && onClick(e);
    }}
  >
    {children}
  </a>
));

const getNameAbbri = (name) => {
  const split = name.toUpperCase().split(" ");
  if (split.length === 1) {
    return split[0];
  } else {
    return [split[0], split[split.length - 1]].join(" ");
  }
};

const UserProfile = ({ user }) => {
  const [activeToolTip, setActiveToolTip] = useState(false);
  const [lengthCom, setLengthCom] = useState(0);

  useEffect(() => {
    if (org) {
      setLengthCom(org.name.length);
    }
  }, []);

  const showToolTip = () => {
    if (lengthCom > 18) setActiveToolTip(true);
  };

  const hideToolTip = () => {
    setActiveToolTip(false);
  };

  const { t } = useTranslation();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  if (!user) return null;
  const avatarUrl = /^http/.test(user?.avatar) ? user?.avatar : undefined;

  const credentialCookie = Cookies.get("credential");
  let org;
  if (credentialCookie) {
    const credential = JSON.parse(Cookies.get("credential"));

    const [userId, orgKey] = credential.identity.split("_");

    org = user.listOrgs.find((o) => o.key === orgKey);
  }

  return (
    <>
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
          {isDesktopOrLaptop && <span>{user ? user.name : ""}</span>}
          <div className={classes["user-profile-wrapper"]}>
            {user?.name && (
              <UserAvatar
                className={classes.userava}
                size="40"
                name={getNameAbbri(user.name)}
                src={avatarUrl}
              />
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className={classes["menu-ctn"]}>
          {/* <Dropdown.Item */}
          <div className={`${classes.item} ${classes["profile-ctn"]}`}>
            {org && (
              <Row
                onMouseEnter={showToolTip}
                onMouseLeave={hideToolTip}
                className={` ${"d-inline-block text-truncate"} ${
                  classes.company
                } ${classes.bold}`}
                style={{ maxWidth: "170px" }}
              >
                {org.name}
              </Row>
            )}
            {org && <Row>{org.domain + "." + process.env.REACT_APP_HOST}</Row>}
            <Row className={classes.profile}>
              <Col className={classes.ava}>
                {user?.name && (
                  <UserAvatar
                    className={classes.userava}
                    size="40"
                    name={getNameAbbri(user.name)}
                    src={avatarUrl}
                  />
                )}
              </Col>
              <Col className={classes.content}>
                <Row className={classes.bold}>{user ? user.name : ""}</Row>
                <Row>@{user ? user.username : ""}</Row>
              </Col>
            </Row>
          </div>
          {/* </Dropdown.Item> */}
          <Dropdown.Item
            className={classes.item}
            href="#"
            onClick={() =>
              window.open(
                `${process.env.REACT_APP_ID_DOMAIN}/auth/realms/production.antbuddy.com/account/`,
                "_blank"
              )
            }
          >
            {t("profile")}
          </Dropdown.Item>
          <Dropdown.Item
            className={classes.item}
            href="#"
            onClick={() =>
              window.open(
                `https://${process.env.REACT_APP_HOST}/signout`,
                "_self"
              )
            }
          >
            {t("logout")}
          </Dropdown.Item>
        </Dropdown.Menu>
        {activeToolTip && (
          <div className={classes.tooltip}>{org ? org.name : ""}</div>
        )}
      </Dropdown>
    </>
  );
};

export default UserProfile;
