import "./Launchpad.css";
import { Row } from "react-bootstrap";
import LaunchpadCard from "../../components/LaunchpadCard";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useAccountState } from "../../context/AccountContext";
import { isMobile } from "react-device-detect";

const Launchpad = () => {
  const { user }: any = useAccountState();
  const navigate = useNavigate();
  const selectOrg = (org) => {
    const userOrg = user.orgs.find((o) => o.orgKey === org.key);
    const config = {
      displayName: user.username,
      uri: `${userOrg.anttel.devices[0].username}@${org.anttel.realm}`,
      authorizationUser: `${userOrg.anttel.devices[0].username}`,
      password: `${userOrg.anttel.devices[0].password}`,
      stunServers: [],
    };

    const setCookie = (config) => {
      Cookies.set(
        "credential",
        JSON.stringify({
          identity: `${user._id}_${org.key}`,
          config: config,
        }),
        { domain: `.${process.env.REACT_APP_HOST}` }
      );
    };

    setCookie(config);

    Cookies.set("domain", org.domain, {
      domain: `.${process.env.REACT_APP_HOST}`,
    });

    // if cookie has "next", delete and redirect to "next"
    const nextUrl = Cookies.get("next");
    if (nextUrl) {
      Cookies.remove("next", { domain: `.${process.env.REACT_APP_HOST}` });
      return (window.location.href = nextUrl);
    }

    navigate("welcome");
  };

  useEffect(() => {
    resetCookiesToDefault();
  }, []);

  useEffect(() => {
    if (user.listOrgs.length === 1) {
      selectOrg(user.listOrgs[0]);
    }
  }, []);

  const resetCookiesToDefault = () => {
    // Set the "credential" and "domain" cookies to their default values
    Cookies.set("credential", JSON.stringify({ identity: "", config: "" }), {
      domain: `.${process.env.REACT_APP_HOST}`,
    });
    Cookies.set("domain", "", { domain: `.${process.env.REACT_APP_HOST}` });
  };

  return (
    <div>
      {user.listOrgs.map((org, index) => (
        <Row key={org.key} className="justify-content-md-center fade-wrap">
          <LaunchpadCard
            org={org}
            onSelect={(org) => selectOrg(org)}
            index={index}
          />
        </Row>
      ))}
    </div>
  );
};

export default Launchpad;
