import "./Header.css";
import { Container, Image } from "react-bootstrap";
import LogoAB from "../../imgs/LogoAntBuddy.png";
// import ContactIcon from '../../imgs/contact.png';
import LanguageSwitcher from "../LanguageSwitcher";
import UserProfile from "../UserProfile";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import "../../index.css";

const Header = ({ user }) => {
  const { t } = useTranslation();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  return (
    <div className="header-wrapper">
      <Container style={{ height: "100%" }}>
        <div className="d-flex" style={{ height: "100%" }}>
          <div className="d-flex align-items-center">
            {/* <Image src={LogoAB} width={isDesktopOrLaptop ? '190' : '100'} /> */}
            <Image src={LogoAB} width="190" />
          </div>
          <div className="d-flex w-100 justify-content-end">
            {/* Language switcher */}
            <div
              className={`d-flex align-items-center justify-content-center cursor-pointer ${
                isDesktopOrLaptop ? "mr-5" : ""
              }`}
            >
              {isDesktopOrLaptop && (
                <span className="header-text">{t("language")}</span>
              )}
              <LanguageSwitcher />
            </div>

            {/* User profile */}
            <div
              className={`d-flex align-items-center justify-content-center cursor-pointer ${
                isDesktopOrLaptop ? "mr-5" : ""
              }`}
            >
              <UserProfile user={user} />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Header;
