import { axiosWithXAccessToken, withSubdomain } from "../apiHelper";
import Cookies from "js-cookie";

const rootBeeUrl = process.env.REACT_APP_ANTCRM_DOMAIN;

export const getOrganizationCrm = async (org) => {
  const URL = withSubdomain(
    `${rootBeeUrl}/api/v1/organizations/current`,
    org?.domain
  );
  const token = Cookies.get("token");
  return await axiosWithXAccessToken(token).get(URL);
};

export const chooseServiceCrm = async (org, serviceName) => {
  const URL = withSubdomain(
    `${rootBeeUrl}/api/v1/onboarding_beeiq/choose_service`,
    org?.domain
  );
  const token = Cookies.get("token");
  return await axiosWithXAccessToken(token).put(URL, { service: serviceName });
};
