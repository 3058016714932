/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

const AccountStateContext = React.createContext({});
const AccountDispatchContext = React.createContext({});

const initialState = {
  user: undefined,
  org: undefined,
  orgCrm: undefined,
  language: "vn",
  isLoggedIn: false,
  isAllowTrial: false,
  isEnabledCustomerService: false,
  isEnabledSales: false,
  isEnabledOmni: false,
};

function accountReducer(state, action) {
  switch (action.type) {
    case "set-user":
      return {
        ...state,
        user: action.value,
      };
    case "set-org":
      const user = state.user;
      const org = action.value;
      const userOrg = user.orgs.find((o) => o.orgKey === org.key);
      const isAllowTrial = userOrg.role
        ? userOrg.role.indexOf("owner") >= 0
        : false;

      return {
        ...state,
        org: action.value,
        isAllowTrial,
      };
    case "set-org-crm":
      const orgCrm = action.value;
      const packages = orgCrm.onboarding.packages;
      // console.log("orgCrm.onboarding.packages", orgCrm.onboarding.packages);

      // if onboarding.isDone = true && !packages.length => enable ca 2 goi
      let isEnabledCustomerService = false,
        isEnabledSales = false,
        isEnabledOmni = false;
      if (orgCrm.onboarding.isDone && !packages.length) {
        isEnabledCustomerService = true;
        isEnabledSales = true;
        isEnabledOmni = true;
      } else {
        // update package
        if (packages.includes("CUSTOMER_SERVICE")) {
          isEnabledCustomerService = true;
        }

        if (packages.includes("SALE")) {
          isEnabledSales = true;
        }

        if (packages.includes("OMNI")) {
          isEnabledOmni = true;
        }
      }

      return {
        ...state,
        orgCrm: action.value,
        isEnabledCustomerService,
        isEnabledSales,
        isEnabledOmni,
      };
    case "set-is-logged-in":
      return {
        ...state,
        isLoggedIn: action.value,
      };
    case "set-lang":
      return {
        ...state,
        language: action.value,
      };
    default:
      return state;
  }
}

function AccountProvider({ children }) {
  const [state, dispatch] = React.useReducer(accountReducer, {
    ...initialState,
  });

  return (
    <AccountStateContext.Provider value={state}>
      <AccountDispatchContext.Provider value={dispatch}>
        {children}
      </AccountDispatchContext.Provider>
    </AccountStateContext.Provider>
  );
}

function useAccountState() {
  const context = React.useContext(AccountStateContext);
  if (context === undefined) {
    throw new Error("useAccountState must be used within a AccountProvider");
  }
  return context;
}

function useAccountDispatch() {
  const context = React.useContext(AccountDispatchContext);
  if (context === undefined) {
    throw new Error("useAccountDispatch must be used within a AccountProvider");
  }
  return context;
}

function useAccountFunctions() {
  const dispatch: any = useAccountDispatch();

  return {
    updateUser: (user) => {
      dispatch({ type: "set-user", value: user });
    },
    updateOrg: (org) => {
      dispatch({ type: "set-org", value: org });
    },
    updateOrgCrm: (orgCrm) => {
      dispatch({ type: "set-org-crm", value: orgCrm });
    },
    updateIsLoggedIn: (isLoggedIn) => {
      dispatch({ type: "set-is-logged-in", value: isLoggedIn });
    },
    updateLanguage: (lang) => {
      dispatch({ type: "set-lang", value: lang });
    },
  };
}

export {
  AccountProvider,
  useAccountState,
  useAccountDispatch,
  useAccountFunctions,
};
