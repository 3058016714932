import { useEffect, useState } from "react";
// import classes from "./VietQRCode.module.css";
import API from "../../apis";

const VietQRCode = ({ qrInfo }) => {
  var QRCode = require("qrcode");
  var canvas = document.getElementById("canvas");
  const [qrCode, setQrCode] = useState<string>("");
  useEffect(() => {
    (async () => {
      try {
        const vietQRCode = await API.Upgrade.getQRCode(qrInfo);
        setQrCode(vietQRCode.data.qrcode);
      } catch (err) {
        console.error(err, "[error get buy package]");
      }
    })();
  }, []);

  let srcUrl;
  QRCode.toDataURL(qrCode, { type: "terminal" }, function (err, url) {
    srcUrl = url;
  });

  return (
    <div id="canvas">
      <img src={srcUrl} />
    </div>
  );
};

export default VietQRCode;
