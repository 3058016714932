import Container from "react-bootstrap/Container";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import SupportButtons from "../../components/SupportButtons";
import { Outlet } from "react-router-dom";
import { useAccountState } from "../../context/AccountContext";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import WelcomeHeading from "../WelcomeHeading/WelcomeHeading";
import classes from "./Layout.module.css";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import API from "../../apis";
import { IconX } from "@tabler/icons-react";
import { IconAlertTriangle } from "@tabler/icons-react";
import {
  checkIsExpired,
  checkIsTrial,
  checkTimeDifference,
  convertDateTime,
  getDate,
} from "../../utils/common";
import FooterUpgrade from "../Upgrade/FooterUpgrade/FooterUpgrade";

const Layout = () => {
  const location = useLocation();
  const { user, orgCrm: orgCrmInit }: any = useAccountState();
  const { t } = useTranslation();
  const [currentDate, setCurrentDate] = useState(getDate());
  const [trialExpired, setTrialExpired] = useState("");
  const [isExpired, setIsExpired] = useState(false);
  const [isTrial, setIsTrial] = useState(false);
  const [orgAR, setOrgAR] = useState();
  const [orgACRM, setOrgACRM] = useState([]);
  const [close, setClose] = useState(false);

  useEffect(() => {
    let orgCrm;
    let org;
    (async () => {
      try {
        let cred = Cookies.get("credential");
        const credential = JSON.parse(cred);

        const [userId, orgKey] = credential.identity.split("_");

        // get org
        org = user.listOrgs.find((o) => o.key === orgKey);

        if (orgCrmInit) {
          orgCrm = orgCrmInit;
        }
      } catch (err) {
        console.error(err, "[error crm api]");
      }
      if (!orgCrm) {
        orgCrm = {
          paymentExpired: org.paymentExpired,
          limitUsers: 0,
          onboarding: {
            packages: [],
            isDone: false,
          },
        };
      }
      if (!orgCrm.paymentExpired) {
        orgCrm.paymentExpired = org.paymentExpired;
      }
      setOrgACRM(orgCrm.onboarding.packages);
      if (org && orgCrm) {
        setClose(false);
        setOrgAR(org);
        setTrialExpired(
          org.paymentExpired > orgCrm.paymentExpired
            ? org.paymentExpired
            : orgCrm.paymentExpired
        );
        setIsTrial(checkIsTrial(org, orgCrm));
        setIsExpired(!checkIsExpired(org, orgCrm));
        const timeDifference = checkTimeDifference(
          org.paymentExpired > orgCrm.paymentExpired
            ? org.paymentExpired
            : orgCrm.paymentExpired,
          currentDate
        );
        if (7 < timeDifference) {
          //Còn 7 ngày nữa hết hạn thì thông báo
          setClose(true);
        }
      } else {
        setClose(true);
      }
    })();
  }, [location.pathname]);

  const handleClose = () => {
    setClose(true);
  };

  return (
    <>
      <div className={`${classes.header}`}>
        <Header user={user} />
      </div>

      <Container
        // fluid
        className={`${classes.container} scroller-1 ${
          isMobile ? "mobile" : ""
        }`}
      >
        {!close && (
          <div className={classes["header-noti"]}>
            <div className={classes["noti-left"]}>
              <IconAlertTriangle
                className={classes["iconAlertTriangle"]}
                size="1rem"
              ></IconAlertTriangle>
              {isExpired && !isTrial && (
                <div className={classes["expired-noti"]}>
                  <span>{t("notiPackagevi")} </span>
                  <span>{t("cloudCallCenter")} </span>
                  {orgACRM.length > 0 && ", "}
                  {orgACRM &&
                    orgACRM.map((item, index) => (
                      <span key={index}>
                        {item === "CUSTOMER_SERVICE" &&
                          t("omnichannelCustomerService")}
                        {item === "SALE" && t("omnichannelRetailSolution")}
                        {item === "OMNI" && t("ominichannelChat")}
                        {orgACRM.length > 1 &&
                          index < orgACRM.length - 1 &&
                          ", "}
                      </span>
                    ))}
                  <span>{t("notiPackageen")} </span>
                  <span>{t("notiTime")}</span>
                  <span className={classes["date-expired"]}>
                    {convertDateTime(trialExpired)}
                  </span>
                  <span>. </span>
                  <span>{t("notiPlease")}</span>
                  <a href="/upgrade">{t("notiUpgrade")}</a>
                  <span>{t("notiUse")}</span>
                </div>
              )}
              {!isExpired && !isTrial && (
                <div className={classes["expired-noti"]}>
                  <span>{t("notiExpired")}</span>
                  <span>{t("notiPlease")}</span>
                  <a href="/upgrade">{t("notiUpgrade")}</a>
                  <span>{t("notiUse")}</span>
                </div>
              )}
              {isExpired && isTrial && (
                <div className={classes["expired-noti"]}>
                  <span>{t("notiTrial")}</span>
                  <span className={classes["date-expired"]}>
                    {convertDateTime(trialExpired)}
                  </span>
                  <span>. </span>
                  <span>{t("notiPlease")}</span>
                  <a href="/upgrade">{t("notiUpgrade")}</a>
                  <span>{t("notiUse")}</span>
                </div>
              )}
              {!isExpired && isTrial && (
                <div className={classes["expired-noti"]}>
                  <span>{t("notiTrialExpired")}</span>
                  <span>{t("notiPlease")}</span>
                  <a href="/upgrade">{t("notiUpgrade")}</a>
                  <span>{t("notiUse")}</span>
                </div>
              )}
            </div>
            <IconX
              className={classes["noti-right"]}
              onClick={handleClose}
            ></IconX>
          </div>
        )}
        <div className={classes["welcomeHeading"]}>
          <WelcomeHeading />
        </div>

        <section className="welcome-content">
          <Outlet />
        </section>
        <SupportButtons />
      </Container>
      <div>
        {/* <Footer /> */}
        <FooterUpgrade />
      </div>
    </>
  );
};

export default Layout;
