import { useTranslation } from "react-i18next";
import { Transition } from "@headlessui/react";

const WelcomeHeading = () => {
  const { t } = useTranslation();
  return (
    <Transition
      show={true}
      appear={true}
      enter={`transition-opacity duration-500`}
      enterFrom="opacity-0"
      enterTo="opacity-100"
    >
      <section className="welcome-heading">
        <h3>{t("welcome")}</h3>
      </section>
    </Transition>
  );
};

export default WelcomeHeading;
