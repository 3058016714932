import classes from "./Button.module.css";
import { ReactElement } from "react";

interface ButtonProps {
  button: string;
  style?: boolean;
  color?: boolean;
  radius?: boolean;
  icon: ReactElement;
  onClick?: () => void;
}

const Button: React.FC<ButtonProps> = (props) => {
  return (
    <button
      className={`${classes.button} ${
        props.style
          ? classes.style
          : props.color
          ? classes.color
          : classes.nonstyle
      } ${props.radius && classes.radius}`}
      onClick={() => {
        props.onClick && props.onClick();
      }}
    >
      {props.icon}
      <div className={classes.content}>{props.button}</div>
    </button>
  );
};

export default Button;
