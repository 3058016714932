import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import "./Welcome.css";
import API from "../../apis";
import ProductCard from "../../components/ProductCard";
import {
  useAccountFunctions,
  useAccountState,
} from "../../context/AccountContext";
import { callChatbot, callVoicebot, chooseService } from "../../helpers/utils";
import {
  checkIsExpired,
  checkIsExpiredAntBot,
  checkIsTrial,
  checkTimeDifference,
  getDate,
  resetTime,
} from "../../utils/common";
import {
  orgChatbotProps,
  orgCrmProps,
  orgVoicebotProps,
} from "../Upgrade/type";
import ProductCardFull from "../../components/ProductCardFull/ProductCardFull";

import avaAR from "../../imgs/avaAR.png";
import avaAC from "../../imgs/avaAC.png";
import avaAB from "../../imgs/avaAB.png";

const Welcome = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { updateOrg, updateOrgCrm } = useAccountFunctions();
  const {
    user,
    org,
    isAllowTrial,
    isEnabledCustomerService,
    isEnabledSales,
    isEnabledOmni,
    orgCrm: orgCrmTest,
  }: any = useAccountState();

  const [orgCrm, setOrgCrm] = useState<orgCrmProps>();
  const [orgChatbot, setOrgChatbot] = useState<orgChatbotProps>();
  const [orgVoicebot, setOrgVoicebot] = useState<orgVoicebotProps>();
  const [isExpired, setIsExpired] = useState(false);
  const [isTrial, setIsTrial] = useState(false);
  const [isEnabledChatbot, setIsEnabledChatbot] = useState(false);
  const [isEnabledVoicebot, setIsEnabledVoicebot] = useState(false);
  const [isUsed, setIsUsed] = useState([
    { name: "OMNI", isUsed: false },
    { name: "CLOUD", isUsed: true },
    { name: "CUSTOMER_SERVICE", isUsed: false },
    { name: "SALE", isUsed: false },
  ]);
  const [tagChatbot, setTagChatbot] = useState(t("trial"));
  const [tagVoicebot, setTagVoicebot] = useState(t("trial"));
  const [tagContent, setTagContent] = useState([
    { name: "OMNI", tagContent: "" },
    { name: "CLOUD", tagContent: "" },
    { name: "CUSTOMER_SERVICE", tagContent: "" },
    { name: "SALE", tagContent: "" },
  ]);

  const tagNameByName = (name: string): string => {
    const item = tagContent.find((item) => item.name === name);
    return item ? item.tagContent : "";
  };

  const productCardsFull = [
    {
      title: t("ominichannelChat"),
      content: (
        <ul className="ul">
          <li>{t("ominichannelChatdst1")}</li>
          <li>{t("ominichannelChatdst2")}</li>
        </ul>
      ),
      image: avaAC,
      onMore: () => window.open(process.env.REACT_APP_ANTCRM, "antcrm"),
      onMoreDisable: () =>
        window.open("https://www.antbuddy.com/h%E1%BB%99i-tho%E1%BA%A1i-%C4%91a-k%C3%AAnh-omni-conversation", "_blank"),
      isTag: true,
      isPricing: false,
      tagContent: tagNameByName("OMNI"),
      priceValue: "175.000 đ ",
      priceInfo: t("priceInfoOC"),
      priceUnit: ` / 1 ${t("unitPrice")}`,
      isEnabled: isEnabledOmni,
      isAllowTrial: isAllowTrial,
      onClick: () =>
        chooseService("OMNI", {
          org,
          isEnabledCustomerService,
          isEnabledSales,
          isEnabledOmni,
        }),
      isRecommend: true,
      isRow: true,
    },
  ];

  const productCards = [
    {
      title: t("cloudCallCenter"),
      content: t("cloudCallCenterdst"),
      image: avaAR,
      onMore: () =>
        window.open("https://www.antbuddy.com/call-center-antring", "_blank"),
      onMoreDisable: () =>
        window.open("https://www.antbuddy.com/t%E1%BB%95ng-%C4%91%C3%A0i-b%C3%A1n-l%E1%BA%BB", "_blank"),
      isTag: true,
      isPricing: false,
      tagContent: tagNameByName("CLOUD"),
      priceValue: "175.000 đ ",
      priceInfo: t("priceInfoCCC"),
      priceUnit: ` / 1 ${t("unitPrice")}`,
      isEnabled: true,
      isAllowTrial: true,
      onClick: () => window.open(process.env.REACT_APP_ANTRING, "_self"),
      isRecommend: false,
      isRow: false,
    },
    {
      title: t("omnichannelCustomerService"),
      content: (
        <>
          {t("omnichannelCustomerServicedst1")} <br />
          {t("omnichannelCustomerServicedst2")}
        </>
      ),
      image: avaAC,
      onMore: () => window.open(process.env.REACT_APP_ANTCRM, "antcrm"),
      onMoreDisable: () =>
        window.open(
          "https://www.antbuddy.com/ch%C4%83m-s%C3%B3c-kh%C3%A1ch-h%C3%A0ng-%C4%91a-k%C3%AAnh-h%E1%BB%A3p-nh%E1%BA%A5t-omnichannel-customer-service",
          "_blank"
        ),
      isTag: true,
      isPricing: false,
      tagContent: tagNameByName("CUSTOMER_SERVICE"),
      priceValue: "345.000 đ ",
      priceInfo: t("priceInfoOCS"),
      priceUnit: ` / 1 ${t("unitPrice")}`,
      isEnabled: isEnabledCustomerService,
      isAllowTrial: isAllowTrial,
      onClick: () =>
        chooseService("CUSTOMER_SERVICE", {
          org,
          isEnabledCustomerService,
          isEnabledSales,
          isEnabledOmni,
        }),
      isRecommend: true,
      isRow: false,
    },
    {
      title: t("omnichannelRetailSolution"),
      content: (
        <>
          {t("omnichannelRetailSolutiondst")} <br />
        </>
      ),
      image: avaAC,
      onMore: () => window.open(process.env.REACT_APP_ANTCRM, "antcrm"),
      onMoreDisable: () =>
        window.open(
          "https://www.antbuddy.com/b%C3%A1n-th%C3%AAm-upsales",
          "_blank"
        ),
      isTag: true,
      isPricing: false,
      tagContent: tagNameByName("SALE"),
      priceValue: "385.000 đ ",
      priceInfo: t("priceInfoORS"),
      priceUnit: ` / 1 ${t("unitPrice")}`,
      isEnabled: isEnabledSales,
      isAllowTrial: isAllowTrial,
      onClick: () =>
        chooseService("SALE", {
          org,
          isEnabledCustomerService,
          isEnabledSales,
          isEnabledOmni,
        }),
      isRecommend: true,
      isRow: false,
    },
    {
      title: t("antbotchatSolution"),
      content: (
        <>
          {t("antbotchatSolutiondst")} <br />
        </>
      ),
      image: avaAB,
      onMore: () => window.open(process.env.REACT_APP_ANTCRM, "antcrm"),
      onMoreDisable: () =>
        window.open("https://www.antbuddy.com/antbot-ai-chatbot-voicebot-rpa", "_blank"),
      isTag: true,
      isPricing: false,
      tagContent: tagChatbot,
      priceValue: t("contact"),
      priceInfo: "",
      priceUnit: "",
      isEnabled: isEnabledChatbot,
      isAllowTrial: isAllowTrial,
      onClick: () => callChatbot({ org }),
      isRecommend: false,
      isRow: false,
    },
    {
      title: t("antbotvoiceSolution"),
      content: (
        <>
          {t("antbotvoiceSolutiondst")} <br />
        </>
      ),
      image: avaAB,
      onMore: () => window.open(process.env.REACT_APP_ANTCRM, "antcrm"),
      onMoreDisable: () =>
        window.open("https://www.antbuddy.com/antbot-ai-chatbot-voicebot-rpa", "_blank"),
      isTag: true,
      isPricing: false,
      tagContent: tagVoicebot,
      priceValue: t("contact"),
      priceInfo: "",
      priceUnit: "",
      isEnabled: isEnabledVoicebot,
      isAllowTrial: isAllowTrial,
      onClick: () => callVoicebot({ org }),
      isRecommend: false,
      isRow: false,
    },
    {
      title: t("omnichannelLoyalty"),
      content: (
        <>
          {t("omnichannelLoyaltydst")} <br />
        </>
      ),
      image: avaAC,
      onMore: () => window.open(process.env.REACT_APP_ANTCRM, "antcrm"),
      onMoreDisable: () =>
        window.open("https://www.antbuddy.com/g%E1%BA%AFn-k%E1%BA%BFt-kh%C3%A1ch-h%C3%A0ng-customer-engagement", "_blank"),
      isTag: true,
      isPricing: false,
      tagContent: t("trial"),
      priceValue: t("contact"),
      priceInfo: "",
      priceUnit: "",
      isEnabled: false,
      isAllowTrial: isAllowTrial,
      onClick: () => {},
      isRecommend: false,
      isRow: false,
    },
  ];

  function setTagAntbot(orgAntbot, setIsEnabledAntbot) {
    const trial = t("trial");
    const used = t("using");
    const expired = t("upgrade");
    let tagAntbot = trial;
    if (orgAntbot && orgAntbot.paymentExpired) {
      if (checkIsExpiredAntBot(orgAntbot)) {
        tagAntbot = expired;
      } else {
        setIsEnabledAntbot(true);
      }
    } else {
      if (
        orgAntbot &&
        (orgAntbot.is_exist === false ||
          (orgAntbot.is_exist === true && !orgAntbot.createdAt))
      ) {
        tagAntbot = trial;
      } else {
        const currentDate = getDate();
        const differentTime = checkTimeDifference(
          resetTime(currentDate),
          resetTime(orgAntbot.createdAt)
        );
        if (differentTime > 8) {
          tagAntbot = expired;
        } else {
          tagAntbot = trial;
        }
      }
    }

    return tagAntbot;
  }

  useEffect(() => {
    const trial = t("trial");
    const used = t("using");
    const expired = t("upgrade");
    const isUsedByName = (name) => {
      const item = isUsed.find((item) => item.name === name);
      return item?.isUsed;
    };
    setTagContent(
      tagContent.map((item) => {
        let tag;
        if (isTrial) {
          tag = trial;
        }
        if (isExpired) {
          tag = expired;
        }
        if (!isExpired && !isTrial && !isUsedByName(item.name)) {
          tag = expired;
        }
        if (!isExpired && !isTrial && isUsedByName(item.name)) {
          tag = used;
        }
        if (orgCrm && orgCrm.onboarding && orgCrm.onboarding.isDone) {
          tag = used;
        }
        return { ...item, tagContent: tag };
      })
    );
    if (orgChatbot) {
      setTagChatbot(setTagAntbot(orgChatbot, setIsEnabledChatbot));
    }
    if (orgVoicebot) {
      setTagVoicebot(setTagAntbot(orgVoicebot, setIsEnabledVoicebot));
    }
  }, [isTrial, isExpired, isUsed, orgCrm, orgChatbot, orgVoicebot, t]);

  useEffect(() => {
    let orgCrm;
    let org;
    (async () => {
      try {
        let cred = Cookies.get("credential");
        if (!cred) {
          // da ve kia
          navigate("/");
        }
        const credential = JSON.parse(cred);
        const [userId, orgKey] = credential.identity.split("_");

        // get org
        org = user.listOrgs.find((o) => o.key === orgKey);
        updateOrg(org);
        // call chatbot
        const { data: dataChat } = await API.Antbot.checkOrgChatbot(org);
        if (dataChat) {
          setOrgChatbot(dataChat);
        }
        // call voicebot
        const { data: dataVoice } = await API.Antbot.checkOrgVoicebot();
        if (dataVoice) {
          setOrgVoicebot(dataVoice);
        }
        // call api crm
        const response = await API.Organization.getOrganizationCrm(org);
        if (response) {
          orgCrm = response.data;
        }
      } catch (err) {
        console.error(err, "[error crm api]");
      }
      if (!orgCrm) {
        orgCrm = {
          paymentExpired: org.paymentExpired,
          limitUsers: 0,
          onboarding: {
            packages: [],
            isDone: false,
          },
        };
      }
      if (!orgCrm.paymentExpired) {
        orgCrm.paymentExpired = org.paymentExpired;
      }
      setOrgCrm(orgCrm);

      updateOrgCrm(orgCrm);
      if (org && orgCrm) {
        setIsTrial(checkIsTrial(org, orgCrm));
        setIsExpired(checkIsExpired(org, orgCrm));
      }
      if (
        orgCrm &&
        orgCrm.onboarding &&
        orgCrm.onboarding.packages &&
        orgCrm.onboarding.packages.length
      ) {
        setIsUsed(
          isUsed.map((item) => ({
            ...item,
            isUsed:
              item.name === "CLOUD"
                ? resetTime(org.paymentExpired) <
                  resetTime(orgCrm.paymentExpired)
                  ? false
                  : true
                : resetTime(org.paymentExpired) >
                  resetTime(orgCrm.paymentExpired)
                ? false
                : orgCrm.onboarding.packages.includes(item.name),
          }))
        );
      }
    })();
  }, []);

  return (
    <div>
      <div className="welcome-container">
        {productCardsFull.map((productCard, index) => (
          <div key={index} className="product-card">
            <ProductCardFull key={index} {...productCard} index={index} />
          </div>
        ))}
      </div>
      <div className="welcome-container">
        {productCards.map((productCard, index) => (
          <div key={index} className="product-card">
            <ProductCard key={index} {...productCard} index={index} />
          </div>
        ))}
      </div>
      <h1 className="antbuddy-slogan">{t("abSlogan")}</h1>
    </div>
  );
};

export default Welcome;
