/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

const UpgradeStateContext = React.createContext({
  items: [],
});

const initialState = {
  items: [],
};

function upgradeReducer(state, action) {
  return initialState;
}

function UpgradeProvider({ children }) {
  const [state, dispatch] = React.useReducer(upgradeReducer, {
    ...initialState,
  });
  const upgradeContext = {
    items: state.items,
  };
  return (
    <UpgradeStateContext.Provider value={upgradeContext}>
      {/* <UpgradeDispatchContext.Provider value={dispatch}> */}
      {children}
      {/* </UpgradeDispatchContext.Provider> */}
    </UpgradeStateContext.Provider>
  );
}

export { UpgradeProvider };
