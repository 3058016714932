import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useState } from "react";

import classes from "./Tab.module.css";
import Package from "../Package/Package";

const Tab = ({ usedPackage }) => {
  const { t } = useTranslation();
  const [numberOfMonth, setNumberOfMonth] = useState(12);
  const [selectedButton, setSelectedButton] = useState(1);

  return (
    <div className={classes.ctn}>
      <div className={classes["button-ctn"]}>
        <div className={classes["button-subctn"]}>
          <Button
            className={selectedButton === 1 ? classes.onclick : classes.button}
            onClick={() => {
              setNumberOfMonth(12);
              setSelectedButton(1);
            }}
          >
            1 {t("year")}
            <div className={classes.discount}>-10%</div>
          </Button>
          <Button
            className={selectedButton === 2 ? classes.onclick : classes.button}
            onClick={() => {
              setNumberOfMonth(6);
              setSelectedButton(2);
            }}
          >
            6 {t("month")}
          </Button>
        </div>
      </div>

      <Package
        numberOfMonthSelected={numberOfMonth}
        usedPackage={usedPackage}
      />
    </div>
  );
};

export default Tab;
