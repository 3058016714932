import { addMonths, formatISO } from "date-fns";
import { PackagesGetPrice } from "../pages/Upgrade/type";

function transformedPackage(
  numberOfMonthSelected: number,
  packageInfo: PackagesGetPrice[]
) {
  const transformedPackage = {
    numberOfMonth: numberOfMonthSelected,
    packages: packageInfo,
  };
  return transformedPackage;
}

function convertPackageName(
  name: string,
  omniName: string,
  cloudName: string,
  saleName: string,
  csName: string,
  csSaleName: string
) {
  let packageName = "";
  if (name === "OMNI") packageName = omniName;
  if (name === "CLOUD") packageName = cloudName;
  if (name === "SALE") packageName = saleName;
  if (name === "CUSTOMER_SERVICE") packageName = csName;
  if (name === "CS_SALE") packageName = csSaleName;
  return packageName;
}
function getDate() {
  const currentDate = new Date();
  const iso8601String = currentDate.toISOString();

  return iso8601String;
}
function convertMoney(money: string) {
  return parseFloat(money).toLocaleString("vi-VN");
}
function addMonthsToISODate(isoDate: string, numberOfMonths: number): string {
  const date = new Date(isoDate);
  const newDate = addMonths(date, numberOfMonths);
  return formatISO(newDate);
}
function convertDateTime(inputDateTime: any) {
  const dateObj = new Date(inputDateTime);
  dateObj.setHours(dateObj.getHours() + 7);

  const hours = String(dateObj.getUTCHours()).padStart(2, "0");
  const minutes = String(dateObj.getUTCMinutes()).padStart(2, "0");
  const seconds = String(dateObj.getUTCSeconds()).padStart(2, "0");
  const day = String(dateObj.getUTCDate()).padStart(2, "0");
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, "0"); // Tháng trong JavaScript là 0-indexed, nên cần +1
  const year = dateObj.getUTCFullYear();

  const formattedDateTime = `${hours}:${minutes}:${seconds} ${day}/${month}/${year}`;

  return formattedDateTime;
}
function checkTimeDifference(dateString1: string, epochTime: string): number {
  const time1 = new Date(dateString1);
  const time2 = new Date(epochTime);

  const delta = time1.getTime() - time2.getTime();

  const days = Math.floor(delta / (1000 * 60 * 60 * 24));
  return days;
}

function checkTimeDifferenceInMonths(
  dateString1: string,
  epochTime: string
): number {
  const time1 = new Date(dateString1);
  const time2 = new Date(epochTime);

  const delta = Math.abs(time1.getTime() - time2.getTime());

  let months = Math.floor(delta / (1000 * 60 * 60 * 24 * 30));
  const days = Math.floor((delta / (1000 * 60 * 60 * 24)) % 30);

  if (days >= 15) {
    months++;
  }

  return months;
}
function isEqual(item1: any, item2: any): boolean {
  // So sánh các thuộc tính cụ thể của các đối tượng
  return (
    item1.name === item2.name &&
    item1.numberOfMonth === item2.numberOfMonth &&
    item1.numberOfUser === item2.numberOfUser &&
    item1.amountPerUnit === item2.amountPerUnit &&
    item1.totalAmount === item2.totalAmount
  );
}
function roundMultiplicationResult(num1: number, num2: number): number {
  const result = num1 * num2;
  const multiplier = Math.pow(10, 0);
  return Math.round(result * multiplier) / multiplier;
}
function getNumberOfUser(packageName: string, usedPackage: any): number {
  if (usedPackage && usedPackage.packages) {
    const item = usedPackage.packages.find(
      (item: any) => item.name === packageName
    );

    if (item) {
      if (item.name === "CLOUD") {
        return item.numberOfUser / 5;
      } else {
        return item.numberOfUser;
      }
    } else {
      return 0; // Hoặc giá trị mặc định khác nếu không tìm thấy
    }
  }
  return 0;
}

function checkIsTrial(org, orgCrm) {
  let isTrial = false;
  if (resetTime(org.paymentExpired) > resetTime(orgCrm.paymentExpired)) {
    if (
      checkTimeDifference(
        resetTime(org.paymentExpired),
        resetTime(org.created)
      ) === 8
    ) {
      return true;
    }
  } else {
    if (
      checkTimeDifference(
        resetTime(orgCrm.paymentExpired),
        resetTime(org.created)
      ) === 8
    ) {
      return true;
    }
  }
  return isTrial;
}

function checkIsExpired(org, orgCrm) {
  const currentDate = getDate();
  let isExpired = true;
  if (resetTime(org.paymentExpired) > resetTime(orgCrm.paymentExpired)) {
    if (org.paymentExpired > currentDate) {
      return false;
    }
  } else {
    if (orgCrm.paymentExpired > currentDate) {
      return false;
    }
  }
  return isExpired;
}
function checkIsExpiredAntBot(orgAntbot) {
  const currentDate = getDate();
  if (orgAntbot.paymentExpired > currentDate) {
    return false;
  } else {
    return true;
  }
}
function createPackageData(
  org: any,
  usedPackageName: string[],
  orgCrm: any
): PackagesGetPrice[] {
  const packages: PackagesGetPrice[] = usedPackageName.map((name) => {
    if (name === "CLOUD") {
      return {
        name: name,
        numberOfUser: org.limitUsers,
      };
    } else {
      return {
        name: name,
        numberOfUser: orgCrm.limitUsers,
      };
    }
  });

  return packages;
}

function checkCS_Sale(selectPackage, isCheckCs_Sale: number) {
  const hasSale = selectPackage.some((item) => item.name === "SALE");
  const hasOmni = selectPackage.some((item) => item.name === "OMNI");
  const hasCustomerService = selectPackage.some(
    (item) => item.name === "CUSTOMER_SERVICE"
  );
  const hasCS_Sale = selectPackage.some((item) => item.name === "CS_SALE");
  if (isCheckCs_Sale === 2) {
    if ((hasSale && hasCustomerService) || hasCS_Sale) return true;
    else return false;
  } else if (isCheckCs_Sale === 3) {
    if (hasSale && hasCustomerService && hasCS_Sale && hasOmni) return true;
    else return false;
  } else {
    if (hasSale && hasCustomerService) return true;
    else return false;
  }
}

function addCloud_Omni(newPackage) {
  const hasCloud = newPackage.some((item) => item.name === "CLOUD");
  const hasOmni = newPackage.some((item) => item.name === "OMNI");
  const hasSale = newPackage.some((item) => item.name === "SALE");
  const hasCustomerService = newPackage.some(
    (item) => item.name === "CUSTOMER_SERVICE"
  );
  const hasCS_Sale = newPackage.some((item) => item.name === "CS_SALE");
  const check =
    hasSale ||
    hasCustomerService ||
    hasCS_Sale ||
    (hasSale && hasCustomerService);
  if (check && !hasOmni) {
    newPackage.push({ name: "OMNI", numberOfUser: 1 });
  } else if (check && !hasCloud) {
    newPackage.push({ name: "CLOUD", numberOfUser: 5 });
  }
}

function resetTime(dateString: string|number): string {
  let date: Date = new Date(dateString);

  date.setHours(0, 0, 0, 0);

  return date.toISOString();
}

export {
  checkIsExpiredAntBot,
  convertPackageName,
  convertMoney,
  getDate,
  convertDateTime,
  checkTimeDifference,
  roundMultiplicationResult,
  addMonthsToISODate,
  checkTimeDifferenceInMonths,
  getNumberOfUser,
  checkIsTrial,
  checkIsExpired,
  createPackageData,
  transformedPackage,
  checkCS_Sale,
  addCloud_Omni,
  resetTime,
};
