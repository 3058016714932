import { IconMouse } from "@tabler/icons-react";
import { convertDateTime } from "../../../utils/common";
import Button from "../UI/Button";
import classes from "./TitlePackage.module.css";
import { useTranslation } from "react-i18next";

const TitlePackage = ({ paymentExpired, usedPackageName, isTrial }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.button}>
        <Button
          radius
          color
          button={t("addPackage")}
          icon={<IconMouse size="18px" strokeWidth="2.5" />}
          onClick={() => window.open("/welcome", "_self")}
        />
      </div>
      <div className={classes.title}>{t("upgradeTitle")}</div>
      <div className={classes["sub-title"]}>
        <div>
          <span>{isTrial ? t("trialPackage") : t("usingPackage")}</span>
          <span className={classes["used-package"]}>{usedPackageName}</span>
        </div>
        <div>
          <span>{t("expired")}: </span>
          <span className={classes.expired}>
            {convertDateTime(paymentExpired)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default TitlePackage;
