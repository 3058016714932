import { Container } from "react-bootstrap";
import Header from "../Header/Header";
import { Outlet } from "react-router-dom";
import { useAccountState } from "../../context/AccountContext";
import { isBrowser, isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import classes from "./HeaderOnly.module.css";
import SupportButtons from "../SupportButtons/SupportButtons";
import FooterUpgrade from "../Upgrade/FooterUpgrade/FooterUpgrade";

const DefaultLayout = () => {
  const { user }: any = useAccountState();
  const { t } = useTranslation();

  return (
    <>
      <div className={`${classes.header}`}>
        <Header user={user} />
      </div>
      <Container
        fluid
        className={`${classes.container} scroller-1 ${
          isMobile ? "mobile" : ""
        }`}
      >
        <section className={`${classes.content}`}>
          <Outlet />
        </section>
        <SupportButtons />
      </Container>
      <div>
        <FooterUpgrade />
      </div>
    </>
  );
};

export default DefaultLayout;
