import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Fragment, useEffect, useState } from "react";
import API from "./apis";
import Layout from "./components/Layout";
import DefaultLayout from "./components/HeaderOnly/HeaderOnly";
import Welcome from "./pages/Welcome";
import Launchpad from "./pages/Launchpad";
import NotFound from "./pages/NotFound";
import { useAccountFunctions, useAccountState } from "./context/AccountContext";
import { ChatWidget } from "@antbuddy-jsc/chat-widget";
// import PayMethod from "./pages/PayMethod/PayMethod";
// import EContract from "./pages/EContract/EContract";
// import Momo from "./pages/Momo/Momo";
import Upgrade from "./pages/Upgrade/Upgrade";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import WelcomeHotjarScript from "./hotjar/Welcome";
import Payment from "./pages/Payment/Payment";

function App() {
  const { t } = useTranslation();

  const { isLoggedIn }: any = useAccountState();
  const { updateUser, updateIsLoggedIn } = useAccountFunctions();
  const [agent, setAgent] = useState<{
    name?: string;
    email?: string;
    id?: string;
  }>({
    name: "",
    email: "",
    id: "",
  });

  const checkIsLoggedIn = async () => {
    try {
      const response = await API.User.getUserInfo();
      updateUser(response.data);

      // Agent for papercups
      setAgent({
        email: response.data.email,
        name: response.data.name,
        id: response.data.key,
      });

      updateIsLoggedIn(true);
    } catch (err: any) {
      console.error(err, "[err]");
      if (err === "Not found token" || err.response.status === 401) {
        window.location.href = `${process.env.REACT_APP_DOMAIN}/signout`;
      }
    }
  };

  useEffect(() => {
    checkIsLoggedIn();
  }, []);

  if (!isLoggedIn) return null;

  const publicRoutes = [
    { path: "/", component: Launchpad, layout: Layout },
    { path: "/welcome", component: Welcome, layout: Layout },
    { path: "/*", component: NotFound, layout: Layout },
    { path: "/upgrade", component: Upgrade, layout: DefaultLayout },
    { path: "/payment", component: Payment, layout: DefaultLayout },
    // { path: "/paymethod", component: PayMethod, layout: DefaultLayout },
    // { path: "/econtract", component: EContract, layout: DefaultLayout },
    // { path: "/momo", component: Momo, layout: DefaultLayout },
  ];

  return (
    <>
      {/* <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Launchpad />} />
            <Route path="welcome" element={<Welcome />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
      </BrowserRouter> */}

      <BrowserRouter>
        <Routes>
          {publicRoutes.map((route, index) => {
            const Page = route.component;
            let Layout;

            if (route.layout) {
              Layout = route.layout;
            } else if (route.layout === null) {
              Layout = Fragment;
            }
            return (
              <Route key={index} path="/" element={<Layout />}>
                <Route
                  key={`route-${index}`}
                  path={route.path}
                  element={<Page />}
                />
              </Route>
            );
          })}
        </Routes>
      </BrowserRouter>
      <div className="live-chat-widget">
        {agent?.email && (
          <ChatWidget
            token="cd23238c-0dd2-4f9e-9ce8-683f1df68dbd"
            inbox="61f0daf0-fe6e-4987-bd34-ec14720c3d67"
            title={t("chatTitle")}
            subtitle={t("chatSubTitle")}
            primaryColor="#33358c"
            greeting={t("chatGreeting")}
            newMessagePlaceholder={t("chatNewMessage")}
            showAgentAvailability={false}
            agentAvailableText={t("chatAvailableText")}
            agentUnavailableText={t("chatUnavailableText")}
            requireEmailUpfront={false}
            requireNameUpfront={false}
            requirePhoneUpfront={false}
            iconVariant="outlined"
            baseUrl="https://chat-sdk.antbuddy.com"
            iframeUrlOverride="https://chat-widget.antbuddy.com"
            customIconUrl={t("chatcustomIconUrl")}
            lang={t("chatLang")}
            useCompanyProfileOnly={true}
            customer={{
              name: agent.name,
              email: agent.email,
              external_id: agent.id,
            }}
          />
        )}
      </div>
      <WelcomeHotjarScript />
    </>
  );
}

export default App;
