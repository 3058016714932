import { axiosWithBearer, tokenDoesNotExist } from "../apiHelper";

const rootUrl = process.env.REACT_APP_DOMAIN;

function getUserInfo() {
  if (tokenDoesNotExist()) return Promise.reject("Not found token");
  return axiosWithBearer().get(`${rootUrl}/api/users/me`);
}

export { getUserInfo };
