const lang = {
  download: "Tải ứng dụng Antbuddy Omnichannel Retail",
  cloudCallCenter: "Giải pháp tổng đài bán lẻ",
  omnichannelCustomerService: "Giải pháp chăm sóc khách hàng",
  omnichannelRetailSolution: "Giải pháp bán thêm",
  omnichannelCSSale:
    "Giải pháp chăm sóc khách hàng và bán thêm đa kênh hợp nhất",
  ominichannelChat: "Giải pháp quản lý hội thoại đa kênh",
  antbotchatSolution: "Giải pháp Antbot Chat",
  antbotvoiceSolution: "Giải pháp Antbot Voice",
  omnichannelLoyalty: "Giải pháp gắn kết khách hàng",
  cloudCallCenterdst:
    "Quản lý hệ thống Hotline, Call Center, ghi âm cuộc gọi, định tuyến thông minh và nhiều tính năng khác tối ưu cho cửa hàng trên nền tảng di động iOS và Android.",
  omnichannelCustomerServicedst1:
    "Chăm sóc khách hàng các kênh khách hàng dùng phổ biến như Call Center, Facebook Fanpage, Zalo OA, Shopee chat v.v.",
  omnichannelCustomerServicedst2:
    "Chuẩn hoá quy trình chăm sóc khách hàng, tự động hoá và di động hoá giúp tăng trải nghiệm khách hàng với thương hiệu, tối đa hoá giá trị vòng đời khách hàng.",
  omnichannelRetailSolutiondst:
    "Lên đơn, quản lý và đồng bộ trạng thái đơn hàng, thông tin khách hàng đa kênh tại Call Center, Facebook Fanpage, Zalo OA, Ecommerce website, Mobile ecommerce apps, sàn TMĐT như Shopee, Tiktok, Lazada hay máy bán hàng POS, ERP v.v.",
  ominichannelChatdst1:
    "Quản lý nhiều trang mạng xã hội, nhiều kênh nhắn tin, nhiều nhân viên chăm sóc khách hàng và bán hàng toàn diện trên một nền tảng.",
  ominichannelChatdst2:
    "Quản lý thông tin khách hàng, lịch sử tương tác và nhiều công cụ khác. Có khả năng nâng cấp để kết nối toàn diện quy trình chăm sóc khách hàng, bán hàng và tiếp thị đa kênh hợp nhất đến toàn doanh nghiệp.",
  antbotchatSolutiondst:
    "Giải pháp AntBOT Chat tự động hoá, thông minh ứng dụng quy trình tự động và mô hình ngôn ngữ lớn.",
  antbotvoiceSolutiondst:
    "Giải pháp AntBOT Voice ứng dụng mô hình ngôn ngữ lớn cho nhân viên CSKH.",
  omnichannelLoyaltydst:
    "Xây dựng chương trình khách hàng thân thiết, tích điểm và đổi quà, chương trình khuyến mãi, mã giảm giá, kết hợp trò chơi, phân đoạn khách hàng. Hỗ trợ đa điểm chạm từ QR Codes, Mobile Apps, Zalo mini apps, Website form, SMS hai chiều, Call Center, SMS, ZNS hay APIs v.v. đồng bộ dữ liệu các kênh trên một nền tảng.",
  trial: "8 ngày dùng thử",
  upgrade: "Nâng cấp ngay",
  using: "Đang sử dụng",
  pricing: "Định giá",
  priceInfoOC: " / 1 người dùng",
  priceInfoCCC: " / 5 người dùng",
  priceInfoOCS: " / 1 người dùng",
  priceInfoORS: " / 1 người dùng",
  unitPrice: "tháng",
  contact: "Liên hệ",
  language: "Ngôn ngữ",
  contactUs: "Liên hệ tư vấn",
  welcome:
    "Chào mừng bạn đến Antbuddy Omnichannel Retail, nền tảng chăm sóc khách hàng hợp kênh, gắn kết và bán thêm cho Chuỗi Bán Lẻ",
  readMore: "Xem thêm",
  useItNow: "Dùng ngay",
  tryItNow: "Thử ngay",
  logout: "Đăng xuất",
  profile: "Hồ sơ người dùng",
  abSlogan: "Khách đâu chăm đó - Bán thêm không khó",
  chatTitle: "Chào mừng bạn đến với Antbuddy Omnichannel Retail",
  chatSubTitle: "Hãy nhắn tin với chúng tôi!",
  chatGreeting: "Xin chào! Bạn cần Antbuddy trợ giúp gì?",
  chatNewMessage: "Bắt đầu nhập...",
  chatAvailableText: "Chúng tôi đang trực tuyến!",
  chatUnavailableText: "Chúng tôi đang vắng mặt.",
  chatLang: "vi",
  chatcustomIconUrl: "https://s3-hn-2.cloud.cmctelecom.vn/storage/logo_vi.png",
  notiPackagevi: "Gói ",
  notiPackageen: " ",
  notiTime: "sẽ hết hạn lúc ",
  notiPlease: "Vui lòng ",
  notiUpgrade: "nâng cấp tài khoản ",
  notiUse: "để tiếp tục sử dụng. ",
  notiExpired: "Tài khoản của bạn đã hết hạn. ",
  notiTrial: "Tài khoản của bạn sẽ hết thời gian dùng thử lúc ",
  notiTrialExpired: "Tài khoản của bạn đã hết thời gian dùng thử. ",

  payment: "Thanh toán",
  paymentInfo: "Thông tin thanh toán",
  fullName: "Họ và tên",
  nameMessage: "Vui lòng nhập họ và tên",
  phone: "Số điện thoại",
  serviceList: "Danh sách dịch vụ",
  package: "Gói dịch vụ",
  packageTitle: "GÓI DỊCH VỤ",
  time: "Thời gian",
  user: "Người dùng",
  unitPricePackage: "Đơn giá",
  startDay: "Ngày bắt đầu",
  endDay: "Ngày kết thúc",
  orderInfo: "Thông tin đơn hàng",
  subTotal: "Tạm tính",
  remainChange: "Tiền dư",
  discount: "Chiết khấu",
  vat: "Thuế và phụ phí",
  total: "Thành tiền",
  qrNoti: "Quét mã QR để thanh toán",
  successNoti: "THANH TOÁN THÀNH CÔNG",
  successMess1: "Bạn đã thanh toán thành công",
  successMess2: "cho gói dịch vụ ",
  successMess3: " ",
  successMess4: "Gói dịch vụ có thời hạn sử dụng là ",
  month: " tháng",
  year: "năm",
  expired: "Hạn sử dụng",
  failNoti: "THANH TOÁN THẤT BẠI",
  failMess:
    "Thanh toán không thành công. Vui lòng kiểm tra thông tin thanh toán và thử lại.",
  fail: "THỬ LẠI",
  add: "Thêm",
  buyAdd: "Mua thêm",
  buyNow: "Mua ngay",
  unit: "người dùng / 1 tháng",
  sum: "Tổng",
  delete: "Xóa",
  cancel: "Hủy",
  select: "Chọn",
  upgradeTitle: "BẢNG GIÁ DỊCH VỤ ANTBUDDY",
  trialPackage: "Gói dùng thử: ",
  usingPackage: "Gói đang sử dụng: ",
  loading: "Đang tải",
  supportChannel: "Kênh hỗ trợ",
  addPackage: "Chọn các gói",
  document: "Tài liệu",
  policy: "Chính sách bảo mật",
  terms: "Điều khoản sử dụng",
  paymentPolicy: "Chính sách thanh toán",
  support: "Hỗ trợ",
  userGuide: "Hướng dẫn sử dụng",
  userGuideVideo: "Video hướng dẫn sử dụng",
  company: "Công ty",
  aboutUs: "Về chúng tôi ",
  downloadApp: "Tải ứng dụng",
  contactInfo: "Thông tin liên hệ",
  slogan: "© 2024 Antbuddy.com - Khách đâu chăm đó, bán thêm không khó",
  mst: "MST: 0314251928 - Sở Kế hoạch và Đầu tư TP.HCM cấp 27/02/2017",
  omniVideo: "https://www.youtube.com/watch?v=5yfbqG63ST4",
  zalochannel: "Zalo PA",
  shopeechannel: "Sàn Shopee",
  lazadachannel: "Sàn Lazada",
  tikichannel: "Sàn Tiki",
};

export default lang;
