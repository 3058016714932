import * as Organization from './list/organization';
import * as Upgrade from './list/upgrade'
import * as User from './list/user';
import * as Helper from './apiHelper';
import * as Antbot from './list/antbot'

const API = {
  Organization,
  Upgrade,
  User,
  Helper,
  Antbot
};

export default API;
