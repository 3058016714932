import { axiosWithBearer } from "../apiHelper";

const rootUrl = process.env.REACT_APP_DOMAIN;

export const getUsedPackage = async (org) => {
  const URL = `${rootUrl}/api/order/${org?.domain}?status=DONE`;
  return await axiosWithBearer().get(URL);
};

export const getAllPackage = async () => {
  const URL = `${rootUrl}/api/package`;
  return await axiosWithBearer().get(URL);
};

export const getPrice = async (org, infoPackage) => {
  const URL = `${rootUrl}/api/package/price/${org?.domain}`;
  return await axiosWithBearer().post(URL, infoPackage);
};

export const getProcessPackage = async (org) => {
  const URL = `${rootUrl}/api/order/${org?.domain}`;
  return await axiosWithBearer().get(URL);
};

export const addNewPackage = async (org, selectPackageTest) => {
  const URL = `${rootUrl}/api/order`;
  return await axiosWithBearer().post(URL, selectPackageTest);
};

export const editStateNewPackage = async (org, id, changeState) => {
  const URL = `${rootUrl}/api/order/${id}`;
  return await axiosWithBearer().put(URL, changeState);
};

export const getQRCode = async (qrInfo) => {
  const URL = `${rootUrl}/api/vietqr/api/createQR`;
  return await axiosWithBearer().post(URL, qrInfo);
};
