import React, { useState } from "react";
import styles from "./Select.module.css";
import arrowDown from "../../imgs/arrow-down.png";
import OutsideClickHandler from "react-outside-click-handler";

interface SelectProps {
  placeholder?: string;
  options: Option[];
  selected: Option | null;
  defaultOption: Default;
  onChange: (selection: Option) => void;
}

export type Option = {
  label: string;
  img: string;
  value: string | number;
};

export type Default = {
  labelDefault: string;
  imgDefault: string;
};

export const Select: React.FC<SelectProps> = ({
  selected,
  defaultOption,
  options,
  onChange,
}) => {
  const [showOptions, setShowOptions] = useState(false);

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShowOptions(false);
      }}
    >
      <div className={styles["select-component"]}>
        <div
          onClick={() => setShowOptions(!showOptions)}
          className={styles["select"]}
        >
          {selected ? (
            <>
              <img
                className={styles["img"]}
                src={selected.img}
                alt={selected.label}
              />
              <span className={styles["label-btn"]}>{selected.label}</span>
            </>
          ) : (
            <>
              <img
                className={styles["img"]}
                src={defaultOption.imgDefault}
                alt={defaultOption.labelDefault}
              />
              <span className={styles["label-btn"]}>
                {defaultOption.labelDefault}
              </span>
            </>
          )}
          <span className={styles["btn-cnt"]}>
            <img className={styles["down-btn"]} alt="" src={arrowDown} />
          </span>
        </div>
        {showOptions && (
          <div className={styles["options"]}>
            {options.map((option) => (
              <div
                onClick={() => {
                  onChange(option);
                  setShowOptions(false);
                }}
                key={option.value}
                className={styles["option"]}
              >
                <img
                  className={styles["img"]}
                  src={option.img}
                  alt={option.label}
                />
                <span>{option.label}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};
