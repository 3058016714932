import { axiosWithBearer } from "../apiHelper";

const rootUrl = process.env.REACT_APP_DOMAIN;

export const createOrgChatbot = async (org) => {
  const URL = `${rootUrl}/api/chatbot/create-org`;
  return await axiosWithBearer().post(URL, { domain: org?.domain });
};

export const checkOrgChatbot = async (org) => {
  const URL = `${rootUrl}/api/chatbot/check-exist/${org?.domain}`;
  return await axiosWithBearer().get(URL);
};

export const createOrgVoicebot = async (org) => {
  const URL = `${rootUrl}/api/voicebot/create-org`;
  return await axiosWithBearer().post(URL, { domain: org?.domain });
};

export const checkOrgVoicebot = async () => {
  const URL = `${rootUrl}/api/voicebot/check-exist`;
  return await axiosWithBearer().get(URL);
};
