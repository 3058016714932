import TitlePackage from "../../components/Upgrade/TitlePackage/TitlePackage";
import Tab from "../../components/Upgrade/UI/Tab";
import classes from "./Upgrade.module.css";
import { Fragment, useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useAccountState } from "../../context/AccountContext";
import API from "../../apis";
import { PackagesGetPrice } from "./type";
import {
  checkIsTrial,
  convertPackageName,
  createPackageData,
  resetTime,
} from "../../utils/common";
import { useTranslation } from "react-i18next";

const Upgrade = () => {
  const { t } = useTranslation();
  const { user, orgCrm: orgCrmInit }: any = useAccountState();
  const [usedPackageName, setUsedPackageName] = useState("");
  const [trialExpired, setTrialExpired] = useState("");
  const [isTrial, setIsTrial] = useState(false);
  const [usedPackage, setUsedPackage] = useState<PackagesGetPrice[]>();

  useEffect(() => {
    let orgCrm;
    let org;
    (async () => {
      try {
        let cred = Cookies.get("credential");
        const credential = JSON.parse(cred);
        const [userId, orgKey] = credential.identity.split("_");
        org = user.listOrgs.find((o) => o.key === orgKey);

        if (orgCrm) {
          orgCrm = orgCrmInit;
        }
      } catch (err) {
        console.error(err, "[error crm api]");
      }
      if (!orgCrm) {
        orgCrm = {
          paymentExpired: org.paymentExpired,
          limitUsers: 0,
          onboarding: {
            packages: [],
            isDone: false,
          },
        };
      }
      if (!orgCrm.paymentExpired) {
        orgCrm.paymentExpired = org.paymentExpired;
      }

      setIsTrial(checkIsTrial(org, orgCrm));
      let packageNameList: string[] = [];

      if (resetTime(org.paymentExpired) >= resetTime(orgCrm.paymentExpired)) {
        packageNameList.push("CLOUD");
      }
      if (resetTime(org.paymentExpired) <= resetTime(orgCrm.paymentExpired)) {
        orgCrm.onboarding.packages.map((item) => {
          packageNameList.push(item);
        });
      }
      if (orgCrm.onboarding.isDone) {
        packageNameList = ["SALE", "CUSTOMER_SERVICE"];
      }

      const packageNames = packageNameList?.map((element) =>
        convertPackageName(
          element,
          t("ominichannelChat"),
          t("cloudCallCenter"),
          t("omnichannelRetailSolution"),
          t("omnichannelCustomerService"),
          t("omnichannelCSSale")
        )
      );
      setUsedPackageName(packageNames.join(", "));
      setUsedPackage(createPackageData(org, packageNameList, orgCrm));
      setTrialExpired(
        orgCrm.paymentExpired
          ? org.paymentExpired > orgCrm.paymentExpired
            ? org.paymentExpired
            : orgCrm.paymentExpired
          : org.paymentExpired
      );
    })();
  }, [t]);
  return (
    <Fragment>
      <div className={classes.container}>
        <TitlePackage
          paymentExpired={trialExpired}
          usedPackageName={usedPackageName}
          isTrial={isTrial}
        />
        <div className={classes.content}>
          {usedPackage ? (
            <Tab usedPackage={usedPackage} />
          ) : (
            <p>{t("loading")}...</p>
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Upgrade;
