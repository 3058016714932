import {
  IconBrandFacebook,
  IconBrandLinkedin,
  IconBrandMessenger,
  IconBrandYoutube,
} from "@tabler/icons-react";
import classes from "./FooterUpgrade.module.css";
import { useTranslation } from "react-i18next";
import ZaloVector from "../../../imgs/zaloVector.png";
import { Tooltip as ReactTooltip } from "react-tooltip";

const ChannelItem = ({ icon, path }) => {
  return (
    <div onClick={() => window.open(path)} className={classes["icon-ctn"]}>
      {icon}
    </div>
  );
};
const FooterUpgrade = () => {
  const { t } = useTranslation();

  const infos = [
    {
      title: t("document"),
      items: [
        {
          value: t("policy"),
          path: "https://www.antbuddy.com/chinh-sach-bao-mat",
        },
        {
          value: t("terms"),
          path: "https://www.antbuddy.com/dieu-khoan-su-dung",
        },
        {
          value: t("paymentPolicy"),
          path: "https://www.antbuddy.com/chinh-sach-thanh-toan",
        },
      ],
    },
    {
      title: t("support"),
      items: [
        { value: t("userGuide"), path: "https://huongdan.antbuddy.com/" },
        {
          value: t("userGuideVideo"),
          path: "https://www.youtube.com/@AntbuddyTeam",
        },
      ],
    },
    {
      title: t("company"),
      items: [
        {
          value: t("aboutUs"),
          path: "https://www.antbuddy.com/antbuddy-ve-chung-toi",
        },
        {
          value: t("downloadApp"),
          path: "https://www.antbuddy.com/tai-ung-dung",
        },
      ],
    },
  ];
  const icons = [
    {
      icon: (
        <img
          data-tooltip-id="zaloOA"
          src={ZaloVector}
          width={28}
          height={28}
          className={classes.icon}
        />
      ),
      path: "https://zalo.me/3673755377776222950",
    },
    {
      icon: (
        <IconBrandMessenger
          data-tooltip-id="messenger"
          className={classes.icon}
          size={24}
        />
      ),
      path: "http://m.me/475373816003996",
    },
    {
      icon: (
        <IconBrandYoutube
          data-tooltip-id="youtube"
          className={classes.icon}
          size={24}
        />
      ),
      path: "https://www.youtube.com/@AntbuddyTeam",
    },
    {
      icon: (
        <IconBrandLinkedin
          data-tooltip-id="linkedin"
          className={classes.icon}
          size={24}
        />
      ),
      path: "https://www.linkedin.com/company/antbuddy-jsc/?viewAsMember=true",
    },
    {
      icon: (
        <IconBrandFacebook
          data-tooltip-id="facebook"
          className={classes.icon}
          size={24}
        />
      ),
      path: "https://www.facebook.com/AntBuddy.ab",
    },
  ];

  return (
    <div className={`"footer-wrapper" ${classes.ctn}`}>
      <div className={classes.row}>
        <div className={`${classes.column} ${classes["sub-column"]}`}>
          <div className={classes.row}>
            {infos.map((info) => {
              return (
                <div className={classes.column}>
                  <div className={classes.title}>{info.title}</div>
                  {info.items.map((item, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => window.open(item.path)}
                        className={classes.item}
                      >
                        {item.value}
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        </div>
        <div className={`${classes.column} ${classes["column-end"]}`}>
          <div className={classes.title}>{t("contactInfo")}</div>
          <div className={` ${classes.item} ${classes["list-icon"]}`}>
            {icons.map((icon, index) => {
              return (
                <ChannelItem key={index} icon={icon.icon} path={icon.path} />
              );
            })}
          </div>
          <div className={classes.item}>+84 89-811-2388</div>
          <div className={classes.item}>info@antbuddy.com</div>
        </div>
      </div>
      <div className={`${classes.row} ${classes.secondary}`}>
        <div className={classes.slogan}>{t("slogan")}</div>
        <div className={classes.mst}>{t("mst")}</div>
      </div>
      <ReactTooltip id="zaloOA" place="bottom" content="Zalo OA" />
      <ReactTooltip id="messenger" place="bottom" content="Messenger" />
      <ReactTooltip id="youtube" place="bottom" content="Youtube" />
      <ReactTooltip id="linkedin" place="bottom" content="LinkedIn" />
      <ReactTooltip id="facebook" place="bottom" content="Facebook" />
    </div>
  );
};

export default FooterUpgrade;
