import React from "react";
import { InputNumber } from "antd";

interface InputNumProps {
  disabled?: boolean;
  min?: number;
  step: number;
  defaultValue?: number;
  onValueChange?: (value: number) => void; // Callback để thông báo giá trị thay đổi
}

const InputNum: React.FC<InputNumProps> = ({
  min,
  step,
  defaultValue,
  onValueChange,
  disabled,
}) => {
  let onChange: ((value: number | null) => void) | undefined;

  if (onValueChange) {
    onChange = (value: number | null) => {
      if (value !== null) {
        const roundedValue = Math.ceil(value / step) * step;
        if (Number.isInteger(roundedValue / step)) {
          onValueChange(roundedValue);
        }
      }
    };
  }

  return (
    <InputNumber
      value={defaultValue}
      min={min}
      defaultValue={defaultValue}
      onChange={onChange}
      step={step}
      keyboard={true}
      controls
      disabled={disabled ? true : false}
    />
  );
};

export default InputNum;
