// @ts-nocheck
import React, { useEffect, useState } from "react";
import "./SupportButtons.css";
import { Image } from "react-bootstrap";
import CloseImage from "../../imgs/close.png";
// import OpenImage from "../../imgs/open.png";
import CallImage from "../../imgs/call.png";
// import ZaloImage from "../../imgs/zalo.png";
import ContactIcon from "../../imgs/contact.png";
import MessengerImage from "../../imgs/messenger.png";
// import { ChatWidget } from "@antbuddy-jsc/chat-widget";
// import { AntBuddy } from "@antbuddy-jsc/browser";
import { useTranslation } from "react-i18next";

const SupportButtons = ({}) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [isShowList, setIsShowList] = useState(true);

  // const [agent, setAgent] = useState<{
  //   name?: string;
  //   email?: string;
  //   id?: string;
  // }>({
  //   name: "",
  //   email: "",
  //   id: "",
  // });

  // const showChat = () => {
  //   AntBuddy.open();
  // };

  useEffect(() => {
    // const zaloWidget = document.getElementsByClassName("zalo-chat-widget");
    const zaloWidget = document.getElementsByClassName("live-chat-widget");
    // console.log("zaloWidget", zaloWidget);
    if (!zaloWidget) return;
    if (isOpen) {
      zaloWidget[0].classList.add("open");
    } else {
      zaloWidget[0].classList.remove("open");
    }
  }, [isOpen]);

  const closeList = () => {
    setIsShowList(false);
    setTimeout(() => {
      setIsOpen(false);
      setIsShowList(true);
    }, 0);
  };

  return (
    <div className="support-buttons-wrapper">
      {!isOpen ? (
        <span
          className="support-icon iopen cursor-pointer"
          onClick={() => setIsOpen(!isOpen)}
        >
          {/* <Image src={OpenImage} width="60" /> */}
          <Image src={t("chatcustomIconUrl")} width="60" />
        </span>
      ) : (
        <span
          className="support-icon iclose cursor-pointer"
          onClick={() => closeList()}
        >
          <Image src={CloseImage} width="60" />
        </span>
      )}
      {isShowList && (
        <div className={`support-buttons-list ${isOpen ? "active" : ""}`}>
          <div
            className="item messenger cursor-pointer"
            onClick={() => window.open("https://m.me/AntBuddy.ab")}
          >
            <Image src={MessengerImage} width="70" />
          </div>

          <div className="item zalo cursor-pointer">
            <Image
              // src={ZaloImage}
              src="https://page.widget.zalo.me/static/images/2.0/Logo.svg"
              width="68"
              onClick={() => window.open("https://zalo.me/3673755377776222950")}
              // style={{ visibility: "hidden" }}
            />
          </div>

          <div
            className="item abtel cursor-pointer"
            onClick={() => window.open("tel:0898112388", "_self")}
          >
            <Image src={CallImage} width="60" />
          </div>

          <div
            className="item contact cursor-pointer"
            onClick={() => window.open("https://www.antbuddy.com", "_target")}
            style={{ visibility: "hidden" }}
          >
            <Image src={ContactIcon} width="30" />
          </div>
        </div>
      )}
    </div>
  );
};

export default SupportButtons;
