import classes from "./Package.module.css";
import { Fragment, useEffect, useState } from "react";
import Button from "../UI/Button";
import { IconPhone, IconPlus, IconShoppingBag } from "@tabler/icons-react";
import { Modal } from "antd";
import Cookies from "js-cookie";
import {
  addCloud_Omni,
  checkCS_Sale,
  convertDateTime,
  convertMoney,
  convertPackageName,
  transformedPackage,
} from "../../../utils/common";
import {
  PackageData,
  PackageItemProps,
  PackagesGetPrice,
} from "../../../pages/Upgrade/type";
import PackageItem from "../PackageItem/PackageItem";
import API from "../../../apis";
import { useAccountState } from "../../../context/AccountContext";
import { useTranslation } from "react-i18next";

interface OrgProps {
  domain: string;
  autoPayment: boolean;
}

interface PackageProps {
  usedPackage: PackagesGetPrice[];
  numberOfMonthSelected: number;
}

const Package: React.FC<PackageProps> = ({
  numberOfMonthSelected,
  usedPackage,
}) => {
  const { t } = useTranslation();

  //POST
  const [allPackage, setAllPackage] = useState<PackagesGetPrice[]>();
  const [selectPackage, setSelectPackage] = useState(usedPackage);
  const [addonPackage, setAddonPackage] = useState<PackagesGetPrice[]>();

  //GET
  const [allPackageFull, setAllPackageFull] = useState<PackageData>();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddButton, setIsAddButton] = useState(true);

  const [isSelectedArray, setIsSelectedArray] = useState<
    { name: string; isSelected: boolean }[]
  >([]);

  const initNumberOfUser = (): Record<string, number> => {
    const init: Record<string, number> = {
      OMNI: 1,
      CLOUD: 5,
      SALE: 1,
      CUSTOMER_SERVICE: 1,
      CS_SALE: 1,
    };
    usedPackage.forEach((item) => {
      if (item.numberOfUser !== undefined) {
        init[item.name] = item.numberOfUser;
      }
    });
    return init;
  };

  const [numberOfUserValues, setNumberOfUserValues] =
    useState(initNumberOfUser);

  const handleUserInputChange = (element: PackageItemProps, value: number) => {
    // Kiểm tra xem element.name có tồn tại trong numberOfUserValues không
    if (numberOfUserValues.hasOwnProperty(element.name)) {
      // Cập nhật giá trị numberOfUser tương ứng
      const updatedNumberOfUserValues = {
        ...numberOfUserValues,
        [element.name]: value,
      };
      let commonValue = 0;
      if (
        element.name === "OMNI" ||
        element.name === "SALE" ||
        element.name === "CUSTOMER_SERVICE" ||
        element.name === "CS_SALE"
      ) {
        commonValue = value;

        // Cập nhật số lượng cho các gói OMNI, SALE, và CUSTOMER_SERVICE
        updatedNumberOfUserValues.OMNI = commonValue;
        updatedNumberOfUserValues.SALE = commonValue;
        updatedNumberOfUserValues.CUSTOMER_SERVICE = commonValue;
        updatedNumberOfUserValues.CS_SALE = commonValue;
      }
      // Kiểm tra và xử lý CLOUD
      const cloudUserValue = updatedNumberOfUserValues.CLOUD;
      if (commonValue - cloudUserValue >= 5) {
        const remainder = (commonValue - cloudUserValue) % 5;
        if (remainder > 0) {
          // Cập nhật numberOfUser của CLOUD
          updatedNumberOfUserValues.CLOUD = cloudUserValue + 5 * remainder;
        } else if (remainder === 0) {
          updatedNumberOfUserValues.CLOUD = commonValue;
        }
      }
      const updatedSelectPackage = selectPackage.map((packageItem) => ({
        ...packageItem,
        numberOfUser: updatedNumberOfUserValues[packageItem.name],
      }));
      setSelectPackage(updatedSelectPackage);

      setNumberOfUserValues(updatedNumberOfUserValues);
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
    const initialSelectedArray = addonPackage?.map((packageItem) => ({
      name: packageItem.name,
      isSelected: false,
    }));
    initialSelectedArray && setIsSelectedArray(initialSelectedArray);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleRemovePackage = (name: string) => {
    let restrictedPackages = ["SALE", "CUSTOMER_SERVICE", "CS_SALE"];
    let restrictedNames = ["CLOUD", "OMNI"];

    let isRestrictedPackagePresent = selectPackage.some((item) =>
      restrictedPackages.includes(item.name)
    );

    if (isRestrictedPackagePresent && restrictedNames.includes(name)) {
      // Do not allow removal
      console.log(`Cannot remove package: ${name}`);
    } else {
      let newPackage = selectPackage.filter((item) => item.name !== name);
      setSelectPackage(newPackage);
    }
  };

  //Set isSelectedArray true trong addonPackage nếu được chọn
  const handleSelectPackage = (name: string) => {
    if (isSelectedArray.length) {
      const newIsSelectedArray = isSelectedArray.map((item) =>
        item.name === name ? { ...item, isSelected: !item.isSelected } : item
      );
      setIsSelectedArray(newIsSelectedArray);
    }
  };

  const handleAddPackage = () => {
    // Filter the selected packages based on isSelectedArray
    const selectedPackages = addonPackage?.filter((packageItem, index) => {
      return isSelectedArray[index]?.isSelected;
    });

    // Add the selected packages to selectPackage
    if (selectedPackages && selectedPackages.length) {
      const newPackage = [...selectPackage, ...selectedPackages];

      addCloud_Omni(newPackage);
      setSelectPackage(newPackage);
    }

    setIsModalOpen(false);
  };

  const [org, setOrg] = useState<OrgProps>();
  const { user }: any = useAccountState();

  useEffect(() => {
    (async () => {
      try {
        let cred = Cookies.get("credential");
        const credential = JSON.parse(cred);

        const [userId, orgKey] = credential.identity.split("_");
        const org = user.listOrgs.find((o) => o.key === orgKey);

        setOrg(org);

        const allPackageValue = await API.Upgrade.getAllPackage();
        setAllPackage(allPackageValue.data.packages);
      } catch (err) {
        console.error(err, "[error crm api]");
      }
    })();
  }, []);

  useEffect(() => {
    if (allPackage) {
      const addonPackage = allPackage.filter(function (allPackageItem) {
        return !selectPackage.some(function (selectPackageItem) {
          return selectPackageItem.name === allPackageItem.name;
        });
      });

      const addonPackageUpdate = addonPackage.map(function (item) {
        return {
          name: item.name,
          numberOfUser:
            item.name === "CLOUD"
              ? 5
              : checkCS_Sale(addonPackage, 3)
              ? 1
              : numberOfUserValues[item.name],
        };
      });

      setAddonPackage(addonPackageUpdate);
      addonPackageUpdate.forEach(function (item) {
        if (numberOfUserValues.hasOwnProperty(item.name)) {
          numberOfUserValues[item.name] = item.numberOfUser;
        }
      });
    }
    if (checkCS_Sale(selectPackage, 1)) {
      let saleItem = selectPackage.find((item) => item.name === "SALE");
      let csItem = selectPackage.find(
        (item) => item.name === "CUSTOMER_SERVICE"
      );

      if (!saleItem) {
        saleItem = {
          name: "SALE",
          numberOfUser: 1,
        };
      }

      if (!csItem) {
        csItem = {
          name: "CUSTOMER_SERVICE",
          numberOfUser: 1,
        };
      }

      const csSaleItem = [
        {
          name: "CS_SALE",
          numberOfUser: csItem.numberOfUser || saleItem.numberOfUser,
        },
        {
          name: "OMNI",
          numberOfUser: csItem.numberOfUser || saleItem.numberOfUser,
        },
        {
          name: "CLOUD",
          numberOfUser:
            csItem.numberOfUser < 5
              ? 5
              : csItem.numberOfUser - (csItem.numberOfUser % 5),
        },
      ];
      const selectPackageNew = selectPackage.filter(
        (item) => item.name !== "CUSTOMER_SERVICE" && item.name !== "SALE"
      );
      csSaleItem.map((item, index) => {
        return selectPackageNew.push(item);
      });

      setSelectPackage(selectPackageNew);
    }
  }, [selectPackage, allPackage]);

  useEffect(() => {
    (async () => {
      try {
        if (org) {
          const allPackageValue = await API.Upgrade.getPrice(
            org,
            transformedPackage(numberOfMonthSelected, selectPackage)
          );
          const data = allPackageValue.data.data;
          setAllPackageFull(data);

          if (!allPackageValue.data.data.addonPackages.length) {
            setIsAddButton(false);
          } else {
            setIsAddButton(true);
          }
        }
      } catch (err) {
        console.error(err, "[error get price select api]");
      }
    })();
  }, [org, selectPackage, numberOfMonthSelected, numberOfUserValues]);

  return (
    <Fragment>
      <div className={classes.container}>
        <div className={`${classes.row} ${classes.header}`}>
          <div>{t("package")}</div>
          <div>
            {isAddButton && (
              <Button
                button={t("add")}
                icon={<IconPlus size="18px" strokeWidth="2.5" />}
                onClick={showModal}
              />
            )}
            {/* START MODAL */}
            <Modal
              title={t("packageTitle")}
              open={isModalOpen}
              onCancel={handleCancel}
              width={650}
            >
              <div className={classes.package}>
                {allPackageFull &&
                  allPackageFull.addonPackages &&
                  allPackageFull.addonPackages.map((element, index) => (
                    <Fragment key={index}>
                      <PackageItem
                        isHideInput
                        isSelected={
                          isSelectedArray.find(
                            (item) => item.name === element.name
                          )?.isSelected
                        }
                        packageName={convertPackageName(
                          element.name,
                          t("ominichannelChat"),
                          t("cloudCallCenter"),
                          t("omnichannelRetailSolution"),
                          t("omnichannelCustomerService"),
                          t("omnichannelCSSale")
                        )}
                        userStep={element.name === "CLOUD" ? 5 : 1}
                        numberOfMonth={element.numberOfMonth}
                        paymentExpired={convertDateTime(element.paymentExpired)}
                        unitPrice={convertMoney(
                          element.amountPerUnit.toString()
                        )}
                        totalPrice={convertMoney(element.amount.toString())}
                        handleSelect={() => handleSelectPackage(element.name)}
                        onValueChange={(value) =>
                          handleUserInputChange(element, value)
                        }
                      />
                      <div className={classes["package-line"]}></div>
                    </Fragment>
                  ))}
                <div className={classes.footer}>
                  <Button
                    button={t("buyAdd")}
                    icon={<IconPlus size="18px" strokeWidth="2.5" />}
                    onClick={() => handleAddPackage()}
                  />
                </div>
              </div>
            </Modal>
            {/* END MODAL */}
          </div>
        </div>

        <div className={classes.package}>
          {allPackageFull &&
            allPackageFull.packages &&
            allPackageFull.packages.map((element, index) => (
              <Fragment key={index}>
                <PackageItem
                  isBuy
                  packageName={convertPackageName(
                    element.name,
                    t("ominichannelChat"),
                    t("cloudCallCenter"),
                    t("omnichannelRetailSolution"),
                    t("omnichannelCustomerService"),
                    t("omnichannelCSSale")
                  )}
                  userStep={element.name === "CLOUD" ? 5 : 1}
                  userValue={element.numberOfUser}
                  numberOfMonth={element.numberOfMonth}
                  paymentExpired={convertDateTime(element.paymentExpired)}
                  unitPrice={convertMoney(element.amountPerUnit.toString())}
                  totalPrice={convertMoney(element.amount?.toString())}
                  handleDelete={() => handleRemovePackage(element.name)}
                  onValueChange={(value) =>
                    handleUserInputChange(element, value)
                  }
                />
                {index < allPackageFull.packages.length - 1 && (
                  <div className={classes["package-line"]}></div>
                )}
              </Fragment>
            ))}
        </div>

        <div className={classes["payment-info"]}>
          <div className={classes.row}>
            <div>{t("subTotal")}</div>
            <div className={classes.bold}>
              {allPackageFull?.totalAmount &&
                convertMoney(allPackageFull?.totalAmount.toString())}{" "}
              Đ
            </div>
          </div>
          <div className={classes.row}>
            <div>{t("remainChange")}</div>
            <div className={classes.bold}>
              -{" "}
              {allPackageFull?.remainChange &&
                convertMoney(allPackageFull?.remainChange.toString())}{" "}
              Đ
            </div>
          </div>
          <div className={classes.row}>
            <div>{t("discount")}</div>
            <div className={classes.bold}>
              -{" "}
              {allPackageFull?.discount &&
                convertMoney(allPackageFull?.discount.toString())}{" "}
              Đ
            </div>
          </div>
          <div className={classes.row}>
            <div>{t("vat")}</div>
            <div className={classes.bold}>
              +{" "}
              {allPackageFull?.vat &&
                convertMoney(allPackageFull?.vat.toString())}{" "}
              Đ
            </div>
          </div>
          <div className={`${classes.row} ${classes.beforeBut}`}>
            <div className={classes.bold}>{t("total")}</div>
            <div className={classes.total}>
              {allPackageFull?.amountPayable &&
                convertMoney(allPackageFull?.amountPayable.toString())}{" "}
              Đ
            </div>
          </div>
          {org?.autoPayment ? (
            org.autoPayment === true ? (
              allPackageFull?.amountPayable &&
              allPackageFull?.amountPayable < 0 ? (
                <Button
                  radius
                  button={t("contact")}
                  icon={<IconPhone size="18px" strokeWidth="2.5" />}
                />
              ) : (
                <Button
                  radius
                  button={t("buyNow")}
                  icon={<IconShoppingBag size="18px" strokeWidth="2.5" />}
                  onClick={async () => {
                    if (allPackageFull) {
                      allPackageFull.domain = org?.domain;
                      allPackageFull.addonPackages &&
                        delete allPackageFull.addonPackages;
                    }
                    try {
                      // const { data } = await API.Upgrade.addNewPackage(
                      //   org,
                      //   allPackageFull
                      // );
                      // if ((data.status = 200)) {
                        window.location.href = "/payment";
                      // }
                    } catch (err) {
                      console.error(err, "error add package");
                    }
                  }}
                />
              )
            ) : (
              <Button
                radius
                button={t("contact")}
                icon={<IconPhone size="18px" strokeWidth="2.5" />}
              />
            )
          ) : (
            <Button
              radius
              button={t("contact")}
              icon={<IconPhone size="18px" strokeWidth="2.5" />}
            />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default Package;
