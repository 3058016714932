const lang = {
  download: "Download AntBuddy Omnichannel Apps",
  cloudCallCenter: "AntRing Cloud Call Center Solution",
  omnichannelCustomerService: "Omnichannel Customer Service Solution",
  omnichannelRetailSolution: "Omnichannel Sales Solution",
  omnichannelCSSale:
    "Omnichannel Customer Service and Omnichannel Sales Solution",
  ominichannelChat: "Omnichannel Messaging Solution",
  antbotchatSolution: "Antbot Chat Solution",
  antbotvoiceSolution: "Antbot Voice Solution",
  omnichannelLoyalty: "Omnichannel Loyalty Solution",
  cloudCallCenterdst:
    "Manage the Hotline system, Call Center, call recording, and various other optimized features on the iOS and Android mobile application platform.",
  omnichannelCustomerServicedst1:
    "Unifies all Customer Service touchpoints such as Call Center Hotline, Facebook Fanpage, Zalo OA, Shopee chat, etc.",
  omnichannelCustomerServicedst2:
    "Standardize the omnichannel customer service process, automation and mobility to enhance customer experience, maximize the customer lifetime value.",
  omnichannelRetailSolutiondst:
    "Create, manage and synchronize order, customer information at any channels such as Hotline Call Center, Facebook Fanpage, Zalo OA, Ecommerce websites, Mobile ecommerce apps, Marketplaces such as Shopee, Tiktok, Lazada shop or POS, ERP etc into unified platform.",
  ominichannelChatdst:
    "Consolidate any customer's chat channels such as Facebook Fanpage, Whatsapp, Zalo OA, etc. of the business into one platform to increase customer experience and boost sales revenue.",
  ominichannelChatdst1:
    "Manage agents social media pages, agents messaging channels, agents customer service staff, and comprehensive sales on one platform." ,
  ominichannelChatdst2:
    "Manage customer data, interaction history and many utilities. It can be upgraded to connect end-to-end omnichannel customer services, sales and marketing of businesses.",
  antbotchatSolutiondst:
    "The Antbot Chat solution, based on Generative AI, provides an assistant to support omnichannel customer care and sales staff through seamless interaction across all customer engagement channels such as phone calls, messaging, comments, social media, etc., aiming to enhance the customer experience, optimize workforce productivity, and improve business efficiency.",
  antbotvoiceSolutiondst:
    "The Antbot Voice solution, based on Generative AI, provides an assistant to support omnichannel customer care and sales staff through seamless interaction across all customer engagement channels such as phone calls, messaging, comments, social media, etc., aiming to enhance the customer experience, optimize workforce productivity, and improve business efficiency.",
  omnichannelLoyaltydst:
    "Build a customer loyalty program, manage membership tiers, automate upgrades and downgrades, accumulate and redeem points, send promotional program notifications, discount codes, incorporate gamification, segment customers, collect and enrich customer data. Support multi-touchpoints through QR Codes, Mobile Apps, Zalo mini apps, Website forms, two-way SMS, Call Center, SMS, ZNS, or APIs, etc., to synchronize data across all channels on a unified platform.",
  trial: "8 day trial",
  upgrade: "Upgrade now",
  using: "Using",
  pricing: "Pricing",
  priceInfoOC: " / 1 user",
  priceInfoCCC: " / 5 users",
  priceInfoOCS: " / 1 user",
  priceInfoORS: " / 1 user",
  unitPrice: "month",
  contact: "Contact us",
  language: "Language",
  contactUs: "Contact us",
  welcome:
    "Welcome to Antbuddy Omnichannel, the platform of Omnichannel Customer Service, Sales and Loyalty for Retail.",
  readMore: "Read more",
  useItNow: "Use it now",
  tryItNow: "Try it now",
  logout: "Logout",
  profile: "User Profile",
  abSlogan: "Connecting touchpoints - Increasing upsale",
  chatTitle: "Welcome you to AntBuddy.com",
  chatSubTitle: "Please message us!",
  chatGreeting:
    "Thank you for contacting AntBuddy.com, what can we do for you?",
  chatNewMessage: "Start typing...",
  chatAvailableText: "We are online!",
  chatUnavailableText: "We are absent.",
  chatLang: "en",
  chatcustomIconUrl: "https://s3-hn-2.cloud.cmctelecom.vn/storage/logo_en.png",
  notiPackagevi: "",
  notiPackageen: " packages ",
  notiTime: "will be expired at ",
  notiPlease: "Please ",
  notiUpgrade: "upgrade your account ",
  notiUse: "to continue using it. ",
  notiExpired: "Your account has expired. ",
  notiTrial: "Your account will be expired at ",
  notiTrialExpired: "Your account has expired. ",

  payment: "Payment",
  paymentInfo: "Payment information",
  fullName: "Full name",
  nameMessage: "Please enter your full name",
  phone: "Phone number",
  serviceList: "List of services",
  package: "Service package",
  packageTitle: "SERVICE PACKAGE",
  time: "Time",
  user: "User",
  unitPricePackage: "Unit price",
  startDay: "Start date",
  endDay: "End date",
  orderInfo: "Order information",
  subTotal: "Subtotal",
  discount: "Discount",
  vat: "Tax and fees",
  total: "Total amount",
  qrNoti: "Scan the QR code to make a payment.",
  successNoti: "PAYMENT SUCCESS",
  successMess1: "You have successfully paid",
  successMess2: "for the ",
  successMess3: "service package",
  successMess4: "The service package has a validity of ",
  month: " months",
  year: "year",
  expired: "Expiration date",
  failNoti: "PAYMENT FAILED",
  failMess:
    "Payment unsuccessful. Please check your payment information and try again.",
  fail: "TRY AGAIN",
  add: "Add",
  buyAdd: "Buy more",
  buyNow: "Buy now",
  unit: "user / 1 month",
  sum: "Total",
  delete: "Delete",
  cancel: "Cancel",
  select: "Select",
  upgradeTitle: "ANTBUDDY SERVICE PRICE LIST",
  trialPackage: "Trial Package: ",
  usingPackage: "Using Package: ",
  loading: "Loading",
  supportChannel: "Support channels",
  addPackage: "Choose the packages",
  document: "Document",
  policy: "Privacy Policy",
  terms: "Terms of Service",
  paymentPolicy: "Payment Policy",
  support: "Support",
  userGuide: "User Guide",
  userGuideVideo: "User Guide Video",
  company: "Company",
  aboutUs: "About Us",
  downloadApp: "Download App",
  contactInfo: "Contact information",
  slogan: "© 2024 AntBuddy.com - Connecting touchpoints, Increasing upsale",
  mst: "TIN: 0314251928 - Issued by Ho Chi Minh City Department of Planning and Investment on 27/02/2017",
  omniVideo: "https://www.youtube.com/watch?v=q70qIrazF8A",
  zalochannel: "Zalo Personal",
  shopeechannel: "Shopee Marketplace",
  lazadachannel: "Lazada Marketplace",
  tikichannel: "Tiki Marketplace",
};
export default lang;
