import React from "react";
import ReactDOM from "react-dom";
import Cookies from "js-cookie";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AccountProvider } from "./context/AccountContext";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/us/translation";
import translationVI from "./locales/vn/translation";
import translationLAO from "./locales/lao/translation";
import translationBAHASA from "./locales/bahasa/translation";
import translationARABICS from "./locales/arabics/translation";
import { UpgradeProvider } from "./context/UpgradeContext";
import { SocketContext, socket } from "./context/SocketContext";

const defaultLanguage = Cookies.get("language") || "vn";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      us: {
        translation: translationEN,
      },
      vn: {
        translation: translationVI,
      },
      arabics: {
        translation: translationARABICS,
      },
      bahasa: {
        translation: translationBAHASA,
      },
      lao: {
        translation: translationLAO,
      },
    },
    lng: defaultLanguage, // if you're using a language detector, do not define the lng option
    fallbackLng: defaultLanguage,

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

ReactDOM.render(
  <React.StrictMode>
    <SocketContext.Provider value={socket}>
      <AccountProvider>
        <UpgradeProvider>
          <App />
        </UpgradeProvider>
      </AccountProvider>
    </SocketContext.Provider>
  </React.StrictMode>,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
