import classes from "./PackageItem.module.css";
import Button from "../UI/Button";
import { IconCheck, IconTrash, IconX } from "@tabler/icons-react";
import InputNum from "../UI/InputNum";
import { useTranslation } from "react-i18next";

interface PackageItemProps {
  packageName: string;
  userStep: number;
  userValue?: number;
  numberOfMonth: number;
  paymentExpired: string;
  unitPrice: string;
  totalPrice: string;
  handleDelete?: () => void;
  handleSelect?: () => void;
  isBuy?: boolean;
  isSelected?: boolean;
  onValueChange: (value: number) => void;
  isHideInput?: boolean;
}

const PackageItem: React.FC<PackageItemProps> = ({
  packageName,
  userStep,
  userValue,
  numberOfMonth,
  paymentExpired,
  unitPrice,
  totalPrice,
  handleDelete,
  handleSelect,
  isBuy,
  isSelected,
  onValueChange,
  isHideInput,
}) => {
  const handleUserInputChange = (value: number) => {
    if (value !== null) {
      onValueChange(value);
    }
  };
  const { t } = useTranslation();
  return (
    <div className={classes.content}>
      <div className={classes.row}>
        <div className={classes.col}>{t("package")}</div>
        <div className={`${classes.col} ${classes.name}`}>{packageName}</div>
      </div>
      {!isHideInput && (
        <div className={classes.row}>
          <div className={classes.col}>{t("user")}</div>
          <div className={classes.col}>
            <InputNum
              min={userStep}
              defaultValue={userValue}
              step={userStep}
              onValueChange={handleUserInputChange}
            />
          </div>
        </div>
      )}
      <div className={classes.row}>
        <div className={classes.col}>{t("time")}</div>
        <div className={`${classes.col} ${classes.info}`}>
          {numberOfMonth} {t("month")}
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.col}>{t("expired")}</div>
        <div className={`${classes.col} ${classes.info}`}>{paymentExpired}</div>
      </div>
      <div className={classes.row}>
        <div className={classes.col}>{t("unitPricePackage")}</div>
        <div className={`${classes.col} ${classes.unitprice}`}>
          <span className={classes.info}>{unitPrice} Đ </span>
          <span>
            / {userStep} {t("unit")}
          </span>
        </div>
      </div>

      <div className={classes.row}>
        <div className={classes.col}>{t("sum")}</div>
        <div className={`${classes.col} ${classes.info}`}>{totalPrice} Đ</div>
      </div>
      <div className={`${classes.row} ${classes.footer}`}>
        {isBuy ? (
          <Button
            style
            button={t("delete")}
            icon={<IconTrash size="18px" strokeWidth="2.5" />}
            onClick={handleDelete}
          />
        ) : isSelected ? (
          <Button
            style
            button={t("cancel")}
            icon={<IconX size="18px" strokeWidth="2.5" />}
            onClick={handleSelect}
          />
        ) : (
          <Button
            button={t("select")}
            icon={<IconCheck size="18px" strokeWidth="2.5" />}
            onClick={handleSelect}
          />
        )}
      </div>
    </div>
  );
};

export default PackageItem;
