import API from "../apis";

export const chooseService = async (
  serviceName,
  { org, isEnabledCustomerService, isEnabledSales, isEnabledOmni }
) => {
  let service = serviceName;
  if (serviceName === "SALE" && isEnabledSales) {
    // console.log(4);
    // console.log(process.env.REACT_APP_ANTCRM);
    window.open(process.env.REACT_APP_ANTCRM, "_self");
    return;
  }
  if (serviceName === "CUSTOMER_SERVICE" && isEnabledCustomerService) {
    // console.log(5);
    // console.log(process.env.REACT_APP_ANTCRM);
    window.open(process.env.REACT_APP_ANTCRM, "_self");
    return;
  }
  if (serviceName === "OMNI" && isEnabledOmni) {
    // console.log(6);
    // console.log(process.env.REACT_APP_ANTCRM);
    window.open(process.env.REACT_APP_ANTCRM, "_self");
    return;
  }
  if (
    (serviceName === "SALE" && isEnabledCustomerService && isEnabledOmni) ||
    (serviceName === "CUSTOMER_SERVICE" && isEnabledSales && isEnabledOmni)
  ) {
    // console.log(0);
    service = "CUSTOMER_SERVICE";
  }
  if (serviceName === "OMNI" && isEnabledSales && isEnabledCustomerService) {
    window.open(process.env.REACT_APP_ANTCRM, "_self");
    return;
  }
  if (serviceName === "OMNI" && !isEnabledCustomerService && isEnabledSales) {
    window.open(process.env.REACT_APP_ANTCRM, "_self");
    return;
  }
  if (serviceName === "OMNI" && isEnabledCustomerService && !isEnabledSales) {
    window.open(process.env.REACT_APP_ANTCRM, "_self");
    return;
  }
  if (serviceName === "CUSTOMER_SERVICE" && isEnabledOmni && !isEnabledSales) {
    service = "CUSTOMER_SERVICE";
  }
  if (serviceName === "CUSTOMER_SERVICE" && !isEnabledOmni && isEnabledSales) {
    service = "CS_SALE";
  }
  if (serviceName === "SALE" && isEnabledOmni && !isEnabledCustomerService) {
    service = "SALE";
  }
  if (serviceName === "SALE" && !isEnabledOmni && isEnabledCustomerService) {
    service = "CS_SALE";
  }

  try {
    const { data } = await API.Organization.chooseServiceCrm(org, service);
    if (data.success) {
      // console.log(5);
      // console.log(process.env.REACT_APP_ANTCRM);
      window.open(process.env.REACT_APP_ANTCRM, "_self");
    }
  } catch (err) {
    console.error(err, "error choose");
  }
};

export const callChatbot = async ({ org }) => {
  try {
    if (org) {
      const { data } = await API.Antbot.checkOrgChatbot(org);
      if (data.is_exist === true) {
        window.open(process.env.REACT_APP_ANTBOT, "_self");
      } else {
        createOrgChatbot({ org });
      }
    }
  } catch (err) {
    console.error(err, "error check org chatbot");
  }
};

export const createOrgChatbot = async ({ org }) => {
  try {
    const { data } = await API.Antbot.createOrgChatbot(org);
    if (data.success) {
      window.open(process.env.REACT_APP_ANTBOT, "_self");
    }
  } catch (err) {
    console.error(err, "error create org chatbot");
  }
};

export const callVoicebot = async ({ org }) => {
  try {
    const { data } = await API.Antbot.checkOrgVoicebot();
    if (data.is_exist === true) {
      window.open(process.env.REACT_APP_VOICEBOT, "_self");
    } else {
      createOrgVoicebot({ org });
    }
  } catch (err) {
    console.error(err, "error check org voicebot");
  }
};

export const createOrgVoicebot = async ({ org }) => {
  try {
    const { data } = await API.Antbot.createOrgVoicebot(org);
    if (data.success) {
      window.open(process.env.REACT_APP_VOICEBOT, "_self");
    }
  } catch (err) {
    console.error(err, "error create org voicebot");
  }
};
