import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import {
  useAccountState,
  useAccountFunctions,
} from "../../context/AccountContext";
import styles from "./LanguageSwitcher.module.css";
import { Select } from "../Select/Select";
import { useState } from "react";
import VNflag from "../../imgs/VN_flag.svg";
import ENflag from "../../imgs/EN_flag.svg";
import THflag from "../../imgs/TH_flag.svg";
import BAHASAflag from "../../imgs/BAHASA_flag.svg";
import ARABICSflag from "../../imgs/ARABICSflag.png";

function getDefaultOption(options: Option[], defaultLanguage: string) {
  const result = options.find((option) => option.value === defaultLanguage);
  if (result) {
    return {
      labelDefault: result.label,
      imgDefault: result.img,
    };
  } else
    return {
      labelDefault: "Tiếng Việt",
      imgDefault: VNflag,
    };
}

export type Option = {
  label: string;
  img: string;
  value: string | number;
};

export type Default = {
  labelDefault: string;
  imgDefault: string;
};

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const { updateLanguage } = useAccountFunctions();

  const switchLanguage = (newlang) => {
    updateLanguage(newlang);
    i18n.changeLanguage(newlang, (err) => {
      if (err) console.error(err, "can not change language");
    });
    Cookies.set("language", newlang);
  };

  const options: Option[] = [
    {
      label: "Tiếng Việt",
      value: "vn",
      img: VNflag,
    },
    {
      label: "English",
      value: "us",
      img: ENflag,
    },
    {
      label: "Arabics",
      value: "arabics",
      img: ARABICSflag,
    },
    {
      label: "Bahasa",
      value: "bahasa",
      img: BAHASAflag,
    },
    {
      label: "ຄົນລາວ Laos",
      value: "lao",
      img: THflag,
    },
  ];

  const defaultLanguage = Cookies.get("language") || "vn";
  const defaultOption: Default = getDefaultOption(options, defaultLanguage);

  const handleLanguageChange = (selection: Option) => {
    setSelectedItem(selection);
    switchLanguage(selection.value);
  };

  const [selectedItem, setSelectedItem] = useState<Option | null>(null);

  return (
    <div className={styles["app"]}>
      <Select
        placeholder="Select a car"
        selected={selectedItem}
        options={options}
        defaultOption={defaultOption}
        onChange={handleLanguageChange}
      />
    </div>
  );
};

export default LanguageSwitcher;
