import React from "react";
import { Select, Space } from "antd";
import { useTranslation } from "react-i18next";

const handleChange = (value: string) => {
  // console.log(`selected ${value}`);
};

interface SelectBoxProps {
  numOfMonth: number;
}

const SelectBox: React.FC<SelectBoxProps> = ({ numOfMonth }) => {
  const { t } = useTranslation();
  return (
    <Space wrap>
      <Select
        disabled
        defaultValue={numOfMonth.toString()}
        style={{ width: 120 }}
        onChange={handleChange}
        options={[
          { value: "12", label: `1 ${t("year")}` },
          { value: "6", label: `6 ${t("month")}` },
        ]}
      />
    </Space>
  );
};

export default SelectBox;
