import { useState, useEffect } from "react";
import classes from "./ProductCardFull.module.css";
import { Col, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Transition } from "@headlessui/react";
import crown from "../../imgs/crown2.png";
import {
  IconCircleCaretLeft,
  IconCircleCaretRight,
  IconClick,
  IconPointer,
  IconSquareRoundedArrowUp,
} from "@tabler/icons-react";
import Facebook from "../../imgs/facebook.png";
import Zalo from "../../imgs/zaloIcon.png";
import Whatsapp from "../../imgs/whatsapp.png";
import Instagram from "../../imgs/instagram.png";
import Shopee from "../../imgs/shopee.svg";
import Telegram from "../../imgs/telegram.png";
import LiveChat from "../../imgs/liveChat.png";
import GoogleBM from "../../imgs/googleBM.png";
import Message from "../../imgs/messengerImage.png";
import Youtube from "../../imgs/youtube.png";
import CHPlay from "../../imgs/googlePlay.png";
import AppStore from "../../imgs/appstoreImg.png";
import Lazada from "../../imgs/lazada.png";
import Tiki from "../../imgs/tiki.jpeg";
import ZaloOA from "../../imgs/zaloOA.png";
import AntBot from "../../imgs/antBot.png";
import Tiktok from "../../imgs/tiktok.png";
// import ReactPlayer from "react-player";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { default as _ReactPlayer } from "react-player/lazy";
import { ReactPlayerProps } from "react-player/types/lib";
const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;

const ProductCardFull = ({
  title,
  content,
  image,
  onMore,
  onMoreDisable,
  isTag,
  tagContent,
  priceValue,
  priceInfo,
  priceUnit,
  isPricing,
  onClick,
  isEnabled,
  isAllowTrial,
  index,
  isRecommend,
  isRow,
}) => {
  const { t } = useTranslation();

  const tooltip = [
    {
      id: "facebookchannel",
      content: "Facebook Comment",
    },
    {
      id: "zalochannel",
      content: t("zalochannel"),
    },
    {
      id: "whatsappchannel",
      content: "Whatsapp",
    },
    {
      id: "instagramchannel",
      content: "Instagram",
    },
    {
      id: "shopeechannel",
      content: t("shopeechannel"),
    },
    {
      id: "telegramchannel",
      content: "Telegram",
    },
    {
      id: "livechatchannel",
      content: "Live Chat",
    },
    {
      id: "ggbmchannel",
      content: "Google Business Messages",
    },
    {
      id: "messagechannel",
      content: "Facebook Messenger",
    },
    {
      id: "youtubechannel",
      content: "Youtube",
    },
    {
      id: "chplaychannel",
      content: "Google Play",
    },
    {
      id: "appstorechannel",
      content: "App Store",
    },
    {
      id: "lazadachannel",
      content: t("lazadachannel"),
    },
    {
      id: "tikichannel",
      content: t("tikichannel"),
    },
    {
      id: "zalooachannel",
      content: "Zalo OA",
    },
    {
      id: "antbotchannel",
      content: "AntBot Chat",
    },
    {
      id: "tiktokchannel",
      content: "TiktokShop Live",
    },
  ];
  const [isShowing, setIsShowing] = useState(false);
  const [isShowMore, setIsShowMore] = useState(false);

  const omniVideoValue = t("omniVideo");
  const url = omniVideoValue !== null ? omniVideoValue : ""; // Xử lý giá trị null

  const disableCond = !isAllowTrial && !isEnabled;
  useEffect(() => {
    setTimeout(() => {
      setIsShowing(true);
    }, 100 * index);
  }, []);

  const getColorClass = (tagContent) => {
    switch (tagContent) {
      case t("trial"):
        return "color-trial";
      case t("using"):
        return "color-used";
      case t("upgrade"):
        return "color-expired";
      default:
        return "";
    }
  };

  return (
    <Transition
      show={isShowing}
      enter={`transition-opacity duration-500`}
      enterFrom="opacity-0"
      enterTo="opacity-100"
    >
      <div
        className={`${classes["product-card-full-wrapper"]} product-card-wrapper`}
      >
        {isTag && (
          <div className="product-card-recommend">
            <div
              className={`product-card-recommend-tag color-trial ${getColorClass(
                tagContent
              )} ${isPricing ? "pricing" : "trial"}`}
              onClick={() => {
                if (tagContent === t("upgrade"))
                  window.open("/upgrade", "_self");
              }}
            >
              {tagContent}
            </div>
          </div>
        )}
        <div className={classes.row}>
          <div className={classes.col}>
            <div className="product-card-title">
              <div
                className={`product-card-img-container ${
                  isRecommend && "product-card-crown-container"
                }`}
              >
                {isRecommend && (
                  <Image src={crown} className="product-card-crown" />
                )}
                <Image src={image} className="product-card-image" />
              </div>
              <div
                className={`${classes["product-card-content"]} product-card-content`}
              >
                <h3>{title}</h3>
                <div className="product-card-price">
                  <div
                    className={`product-card-price-content ${
                      isRow && "product-card-price-content-row"
                    }`}
                  >
                    <span className="product-card-price-value">
                      {priceValue && priceValue}
                    </span>
                    <span className="product-card-price-info">
                      {priceInfo && priceInfo}
                    </span>
                    <span>{priceUnit}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="product-card-description">{content}</div>
            <div>
              <button
                disabled={disableCond}
                className={`${classes["product-card-button"]} product-card-button`}
                onClick={() =>
                  tagContent === t("upgrade")
                    ? window.open("/upgrade", "_self")
                    : onClick && onClick()
                }
              >
                {tagContent === t("upgrade") ? (
                  <IconSquareRoundedArrowUp className="icon" size={18} />
                ) : isEnabled ? (
                  <IconPointer className="icon" size={18} />
                ) : (
                  <IconClick className="icon" size={18} />
                )}
                {tagContent === t("upgrade")
                  ? t("upgrade")
                  : isEnabled
                  ? t("useItNow")
                  : t("tryItNow")}
              </button>
              <div className={`${classes["read-more"]} read-more`}>
                <div className={classes["icon-ctn"]}>
                  <div className={classes.title}>{t("supportChannel")}</div>
                  <div
                    data-tooltip-id="facebookchannel"
                    className={classes["icon-channel"]}
                  >
                    <img src={Facebook} />
                  </div>
                  <div
                    data-tooltip-id="zalochannel"
                    className={classes["icon-channel"]}
                  >
                    <img src={Zalo} />
                  </div>
                  <div
                    data-tooltip-id="whatsappchannel"
                    className={classes["icon-channel"]}
                  >
                    <img src={Whatsapp} />
                  </div>
                  <div
                    data-tooltip-id="instagramchannel"
                    className={classes["icon-channel"]}
                  >
                    <img src={Instagram} />
                  </div>
                  <div
                    data-tooltip-id="shopeechannel"
                    className={classes["icon-channel"]}
                  >
                    <img src={Shopee} />
                  </div>
                  {isShowMore && (
                    <>
                      <div
                        data-tooltip-id="telegramchannel"
                        className={classes["icon-channel"]}
                      >
                        <img src={Telegram} />
                      </div>
                      <div
                        data-tooltip-id="livechatchannel"
                        className={classes["icon-channel"]}
                      >
                        <img src={LiveChat} />
                      </div>
                      <div
                        data-tooltip-id="ggbmchannel"
                        className={classes["icon-channel"]}
                      >
                        <img src={GoogleBM} />
                      </div>
                      <div
                        data-tooltip-id="messagechannel"
                        className={classes["icon-channel"]}
                      >
                        <img src={Message} />
                      </div>
                      <div
                        data-tooltip-id="youtubechannel"
                        className={classes["icon-channel"]}
                      >
                        <img src={Youtube} />
                      </div>
                      <div
                        data-tooltip-id="chplaychannel"
                        className={classes["icon-channel"]}
                      >
                        <img src={CHPlay} />
                      </div>
                      <div
                        data-tooltip-id="appstorechannel"
                        className={classes["icon-channel"]}
                      >
                        <img src={AppStore} />
                      </div>
                      <div
                        data-tooltip-id="lazadachannel"
                        className={classes["icon-channel"]}
                      >
                        <img src={Lazada} />
                      </div>
                      <div
                        data-tooltip-id="tikichannel"
                        className={classes["icon-channel"]}
                      >
                        <img src={Tiki} />
                      </div>
                      <div
                        data-tooltip-id="zalooachannel"
                        className={classes["icon-channel"]}
                      >
                        <img src={ZaloOA} />
                      </div>
                      <div
                        data-tooltip-id="antbotchannel"
                        className={classes["icon-channel"]}
                      >
                        <img src={AntBot} />
                      </div>
                      <div
                        data-tooltip-id="tiktokchannel"
                        className={classes["icon-channel"]}
                      >
                        <img src={Tiktok} />
                      </div>
                    </>
                  )}
                  {!isShowMore && (
                    <IconCircleCaretRight
                      onClick={() => {
                        setIsShowMore(true);
                      }}
                      className={classes["channel-detail"]}
                    />
                  )}
                  {isShowMore && (
                    <IconCircleCaretLeft
                      onClick={() => {
                        setIsShowMore(false);
                      }}
                      className={classes["channel-detail"]}
                    />
                  )}
                </div>
                <a
                  href="#"
                  className="product-card-link"
                  onClick={() => onMoreDisable && onMoreDisable()}
                >
                  {t("readMore")}
                </a>
              </div>
            </div>
          </div>
          <div className={classes.col}>
            <div className={classes["youtube-video-container"]}>
              <ReactPlayer
                muted
                className={classes.reactPlayer}
                width="90%"
                height="auto"
                url={url}
                loop
                controls
                playing
              />
            </div>
          </div>
        </div>
      </div>
      {tooltip.map((element, index) => {
        return (
          <ReactTooltip
            key={index}
            id={element.id}
            place="bottom"
            content={element.content}
          />
        );
      })}
    </Transition>
  );
};

export default ProductCardFull;
