import { useState, useEffect } from "react";
import "./ProductCard.css";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Transition } from "@headlessui/react";
import crown from "../../imgs/crown2.png";
import {
  IconClick,
  IconPointer,
  IconSquareRoundedArrowUp,
} from "@tabler/icons-react";

const ProductCard = ({
  title,
  content,
  image,
  onMore,
  onMoreDisable,
  isTag,
  tagContent,
  priceValue,
  priceInfo,
  priceUnit,
  isPricing,
  onClick,
  isEnabled,
  isAllowTrial,
  index,
  isRecommend,
  isRow,
}) => {
  const [isShowing, setIsShowing] = useState(false);
  const { t } = useTranslation();
  const disableCond = !isAllowTrial && !isEnabled;
  useEffect(() => {
    setTimeout(() => {
      setIsShowing(true);
    }, 100 * index);
  }, []);

  const getColorClass = (tagContent) => {
    switch (tagContent) {
      case t("trial"):
        return "color-trial";
      case t("using"):
        return "color-used";
      case t("upgrade"):
        return "color-expired";
      default:
        return "";
    }
  };

  return (
    <Transition
      show={isShowing}
      enter={`transition-opacity duration-500`}
      enterFrom="opacity-0"
      enterTo="opacity-100"
    >
      <div className="product-card-wrapper">
        {isTag && (
          <div className="product-card-recommend">
            <div
              className={`product-card-recommend-tag color-trial ${getColorClass(
                tagContent
              )} ${isPricing ? "pricing" : "trial"}`}
              onClick={() => {
                if (tagContent === t("upgrade"))
                  window.open("/upgrade", "_self");
              }}
            >
              {tagContent}
            </div>
          </div>
        )}

        <div className="product-card-title">
          <div
            className={`product-card-img-container ${
              isRecommend && "product-card-crown-container"
            }`}
          >
            {isRecommend && (
              <Image src={crown} className="product-card-crown" />
            )}
            <Image src={image} className="product-card-image" />
          </div>
          <div className="product-card-content">
            <h3>{title}</h3>
            <div className="product-card-price">
              <div
                className={`product-card-price-content ${
                  isRow && "product-card-price-content-row"
                }`}
              >
                <span className="product-card-price-value">
                  {priceValue && priceValue}
                </span>
                <span className="product-card-price-info">
                  {priceInfo && priceInfo}
                </span>
                <span>{priceUnit}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="product-card-description">{content}</div>
        <div>
          <button
            disabled={disableCond}
            className="product-card-button"
            onClick={() =>
              tagContent === t("upgrade")
                ? window.open("/upgrade", "_self")
                : onClick && onClick()
            }
          >
            {tagContent === t("upgrade") ? (
              <IconSquareRoundedArrowUp className="icon" size={18} />
            ) : isEnabled ? (
              <IconPointer className="icon" size={18} />
            ) : (
              <IconClick className="icon" size={18} />
            )}
            {tagContent === t("upgrade")
              ? t("upgrade")
              : isEnabled
              ? t("useItNow")
              : t("tryItNow")}
          </button>
          <div className="read-more">
            <a
              href="#"
              className="product-card-link"
              onClick={() =>
                // isEnabled
                //   ? onMoreDisable && onMoreDisable()
                //   : isAllowTrial
                //   ? onMore && onMore()
                // :
                onMoreDisable && onMoreDisable()
              }
            >
              {t("readMore")}
            </a>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default ProductCard;
